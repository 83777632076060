// @flow

export default (type: string): string => {
  const index = Math.max(
    type.indexOf('_REQUEST'),
    type.indexOf('_SUCCESS'),
    type.indexOf('_FAILURE')
  );

  return type.substr(0, index);
};
