// @flow

import React from 'react';
import classNames from 'classnames';

// $FlowIgnore
import { ReactComponent as Logo } from 'assets/images/dwa-swoosh.svg';

type Props = {
  small?: boolean,
  action?: boolean,
};

const Loading = ({ small = false, action = false }: Props) => {
  const loaderClasses = classNames('loader-wrapper', {
    'is-small': small,
    'is-action': action,
  });

  return (
    <div className={loaderClasses}>
      <div className="loader">
        <Logo />
      </div>
    </div>
  );
};

export default Loading;
