// @flow

import { schema } from 'normalizr';

const configurationSchema = new schema.Entity(
  'configurations',
  {},
  { idAttribute: 'key' }
);

export const GET_CONFIGURATIONS_REQUEST = 'GET_CONFIGURATIONS_REQUEST';
export const GET_CONFIGURATIONS_SUCCESS = 'GET_CONFIGURATIONS_SUCCESS';
export const GET_CONFIGURATIONS_FAILURE = 'GET_CONFIGURATIONS_FAILURE';

export const getConfigurations = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_CONFIGURATIONS_REQUEST,
      GET_CONFIGURATIONS_SUCCESS,
      GET_CONFIGURATIONS_FAILURE,
    ],
    url: 'configurations',
    httpMethod: 'GET',
    normalizeSchema: { configurations: [configurationSchema] },
  },
});

export const UPDATE_CONFIGURATION_REQUEST = 'UPDATE_CONFIGURATION_REQUEST';
export const UPDATE_CONFIGURATION_SUCCESS = 'UPDATE_CONFIGURATION_SUCCESS';
export const UPDATE_CONFIGURATION_FAILURE = 'UPDATE_CONFIGURATION_FAILURE';

export type UpdateConfigurationArgsT = {
  id: string,
  value: ?(string | number),
};

export const updateConfiguration = ({
  id,
  value,
}: UpdateConfigurationArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CONFIGURATION_REQUEST,
      UPDATE_CONFIGURATION_SUCCESS,
      UPDATE_CONFIGURATION_FAILURE,
    ],
    url: `configurations/${id}`,
    httpMethod: 'PUT',
    contentType: 'formdata',
    payload: {
      configuration: {
        value,
      },
    },
    normalizeSchema: { configuration: configurationSchema },
  },
});
