// @flow

import React from 'react';
import {
  // $FlowFixMe
  useRouteMatch,
  Switch,
  Route,
} from 'react-router-dom';

import NotFoundRedirect from 'views/NotFoundRedirect';
import Login from './Login';
import RecoverAccount from './RecoverAccount';
import ResetPassword from './ResetPassword';
import AccountUnlock from './AccountUnlock';

const isAccountLockable =
  parseInt(process.env.REACT_APP_ACCOUNT_LOCKABLE, 10) === 1;

const LoginRoot = () => {
  const { path } = useRouteMatch({ path: '/login' });

  return (
    <Switch>
      <Route path={path} exact component={Login} />
      <Route
        exact
        path={`${path}/recover_account`}
        component={RecoverAccount}
      />
      <Route exact path={`${path}/reset_password`} component={ResetPassword} />
      {isAccountLockable ? (
        <Route
          exact
          path={`${path}/unlock_account`}
          component={AccountUnlock}
        />
      ) : null}
      <Route component={NotFoundRedirect} />
    </Switch>
  );
};

export default LoginRoot;
