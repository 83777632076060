import alerts from './alerts';
import attachments from './attachments';
import dashboard from './dashboard';
import data from './data';
import device from './device';
import logs from './logs';
import search from './search';
import pendingMessages from './pendingMessages';
import configurations from './configurations';
import heatDisinfections from './heatDisinfections';

export default {
  ...alerts,
  ...attachments,
  ...dashboard,
  ...device,
  ...logs,
  ...search,
  ...data,
  ...pendingMessages,
  ...configurations,
  ...heatDisinfections,
};
