// @flow

import { fromJS } from 'immutable';

import {
  GET_RIGHTS_SUCCESS,
  UPDATE_RIGHTS_FOR_ROLE_SUCCESS,
} from 'actions/permissions';

import deindex from 'utils/deindex';

const RIGHTS_PATH = ['rights'];
const ROLES_PATH = ['roles'];

export default {
  [GET_RIGHTS_SUCCESS]: (state: any, action: any): any => {
    const { response } = action.payload;
    const { entities } = response;

    return state.setIn(RIGHTS_PATH, fromJS(entities.rights || {}));
  },
  [UPDATE_RIGHTS_FOR_ROLE_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    return state.mergeIn(ROLES_PATH, fromJS(entities.roles));
  },
};

export const rightsSelector = (state: any): ?Array<RightT> => {
  const rights = state.entities.getIn(RIGHTS_PATH).toJS();

  if (!rights) return undefined;

  return deindex(rights);
};
