// @flow

import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
// $FlowFixMe
import { useLocation, Redirect } from 'react-router-dom';

import { getCurrentUser } from '../actions/users';
import {
  authenticatedSelector,
  currentUser as currentUserSelector,
} from '../reducers/auth';

import Loading from 'components/Loading';

function CurrentUserProvider({ children }: { children: React.Node }) {
  const dispatch = useDispatch();
  const location = useLocation();

  const isFetching = useSelector((state) => {
    const { network } = state;
    return network.GET_CURRENT_USER === undefined || network.GET_CURRENT_USER;
  });

  const isAuthenticated = useSelector(authenticatedSelector);
  const currentUser = useSelector(currentUserSelector);

  React.useEffect(() => {
    if (isAuthenticated) {
      dispatch(getCurrentUser());
    }
  }, [isAuthenticated, dispatch]);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          hash: location.hash,
          state: { referrer: location },
        }}
      />
    );
  }

  if (isFetching && !currentUser) {
    return <Loading />;
  }

  return children;
}

export default CurrentUserProvider;
