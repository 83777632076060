// @flow

import type { IntlShape } from 'react-intl';

export const format = (regexes: Array<RegExp>, intl: IntlShape) => ({
  type: 'format',
  args: regexes,
  intl,
});

export const maxLength = (length: number, intl: IntlShape) => ({
  type: 'max_length',
  args: [length],
  intl,
});

export const maxValue = (value: number, intl: IntlShape) => ({
  type: 'max_value',
  args: [value],
  intl,
});

export const minLength = (length: number, intl: IntlShape) => ({
  type: 'min_length',
  args: [length],
  intl,
});

export const minValue = (value: number, intl: IntlShape) => ({
  type: 'min_value',
  args: [value],
  intl,
});

export const required = (intl: IntlShape) => ({
  type: 'required',
  args: [true],
  intl,
});

export const contentTypes = (intl: IntlShape, types: Array<string>) => ({
  type: 'content_type',
  args: [types],
  intl,
});

export const strongPassword = (intl: IntlShape) => ({
  type: 'strong_password',
  intl,
});
