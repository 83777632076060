import { createStore, applyMiddleware, compose } from 'redux';
import { createLogger } from 'redux-logger';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import { Iterable } from 'immutable';

import api from './middleware/api';
import rootReducer from './reducers';

const isDev = process.env.NODE_ENV !== 'production';
const isLoggingEnabled = parseInt(process.env.REACT_APP_REDUX_LOGGER, 10) === 1;
const middlewares = [thunk, api];

if (isDev || isLoggingEnabled) {
  const stateTransformer = (state) =>
    Object.keys(state).reduce((acc, key) => {
      const keyState = state[key];
      return Iterable.isIterable(keyState)
        ? { ...acc, [key]: keyState.toJS() }
        : { ...acc, [key]: keyState };
    }, {});

  const logger = createLogger({
    level: 'info',
    collapsed: true,
    stateTransformer,
  });

  middlewares.push(logger);
}

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers =
  isDev || isLoggingEnabled
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
    : compose;

export default (preloadedState) => {
  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  const store = createStore(rootReducer, preloadedState, enhancer);
  const persistor = persistStore(store);

  return { store, persistor };
};
