// @flow

import { schema } from 'normalizr';
import type { Dispatch } from 'redux';

import { getCurrentUser } from 'actions/users';
import createSerializer, { serializeTime } from 'utils/schemaSerializers';

export const centerSchema = new schema.Entity(
  'centers',
  {},
  {
    processStrategy: createSerializer({
      updatedAt: serializeTime,
      createdAt: serializeTime,
    }),
  }
);

export const GET_CENTERS_REQUEST = 'GET_CENTERS_REQUEST';
export const GET_CENTERS_SUCCESS = 'GET_CENTERS_SUCCESS';
export const GET_CENTERS_FAILURE = 'GET_CENTERS_FAILURE';

export const getCenters = (): NetworkActionT => ({
  CALL_API: {
    types: [GET_CENTERS_REQUEST, GET_CENTERS_SUCCESS, GET_CENTERS_FAILURE],
    url: 'centers',
    httpMethod: 'GET',
    params: { sortKeys: 'name asc' },
    normalizeSchema: { centers: [centerSchema] },
  },
});

export const ADD_CENTER_REQUEST = 'ADD_CENTER_REQUEST';
export const ADD_CENTER_SUCCESS = 'ADD_CENTER_SUCCESS';
export const ADD_CENTER_FAILURE = 'ADD_CENTER_FAILURE';

type AddCenterArgsT = {
  name: string,
  emailContact: ?string,
};

export const addCenter =
  ({ name, emailContact }: AddCenterArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [ADD_CENTER_REQUEST, ADD_CENTER_SUCCESS, ADD_CENTER_FAILURE],
        url: 'centers',
        httpMethod: 'POST',
        payload: {
          center: { name, emailContact },
        },
        normalizeSchema: { center: centerSchema },
      },
    }).then(() => dispatch(getCurrentUser()));

export const UPDATE_CENTER_REQUEST = 'UPDATE_CENTER_REQUEST';
export const UPDATE_CENTER_SUCCESS = 'UPDATE_CENTER_SUCCESS';
export const UPDATE_CENTER_FAILURE = 'UPDATE_CENTER_FAILURE';

export type UpdateCenterArgsT = {
  id: string,
  name: string,
  emailContact: ?string,
};

export const updateCenter = ({
  id,
  name,
  emailContact,
}: UpdateCenterArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CENTER_REQUEST,
      UPDATE_CENTER_SUCCESS,
      UPDATE_CENTER_FAILURE,
    ],
    url: `centers/${id}`,
    httpMethod: 'PUT',
    payload: {
      center: { name, emailContact },
    },
    normalizeSchema: { center: centerSchema },
  },
});

export const DELETE_CENTER_REQUEST = 'DELETE_CENTER_REQUEST';
export const DELETE_CENTER_SUCCESS = 'DELETE_CENTER_SUCCESS';
export const DELETE_CENTER_FAILURE = 'DELETE_CENTER_FAILURE';

type DeleteCenterArgsT = {
  id: string,
};

export const deleteCenter =
  ({ id }: DeleteCenterArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          DELETE_CENTER_REQUEST,
          DELETE_CENTER_SUCCESS,
          DELETE_CENTER_FAILURE,
        ],
        url: `centers/${id}`,
        httpMethod: 'DELETE',
        actionPayload: { centerId: id },
      },
    }).then(() => dispatch(getCurrentUser()));
