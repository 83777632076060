// @flow

import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import Pages from './Pages';

const t = defineMessages({
  headline: {
    id: 'error_headline',
    defaultMessage: 'Woops, looks like an error occurred.',
  },
  refreshState: { id: 'error_refresh_state', defaultMessage: 'Reload the app' },
  refreshStateTitle: {
    id: 'error_refresh_state_title',
    defaultMessage: 'Reload this page',
  },
});

type Props = {
  onClickCallBack?: () => void, // Optional
};

const Error = ({ onClickCallBack }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Pages onClick={onClickCallBack ? onClickCallBack() : null}>
      <div className="page-container">
        <h2 className="page-title is-shaking">
          <i className="material-icons">flash_on</i>
          <br />
          <FormattedMessage {...t.headline} />
        </h2>

        <br />

        <a
          href={window.location.origin}
          title={formatMessage(t.refreshStateTitle)}
          className="button button-success"
        >
          <span>
            <FormattedMessage {...t.refreshState} />
          </span>
        </a>
      </div>
    </Pages>
  );
};

export default Error;
