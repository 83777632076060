// @flow

import { schema } from 'normalizr';

import createSerializer, { serializeTime } from 'utils/schemaSerializers';

import { centerSchema } from 'actions/centers';

const deviceDashboardFiltersSchema = new schema.Entity('filters');

const dashboardDeviceSchema = new schema.Entity(
  'devices',
  {},
  {
    processStrategy: createSerializer({
      lastSeenAt: serializeTime,
    }),
  }
);

const deviceDashboardTenantsSchema = new schema.Entity('tenants', {
  centers: [
    new schema.Entity('centers', {
      devices: [dashboardDeviceSchema],
    }),
  ],
});

export const deviceSchema = new schema.Entity(
  'devices',
  { center: centerSchema },
  {
    processStrategy: createSerializer({
      createdAt: serializeTime,
      updatedAt: serializeTime,
    }),
  }
);

const deviceAlertSchema = new schema.Entity(
  'alerts',
  {},
  {
    processStrategy: (alert) => ({
      ...alert,
      ...createSerializer({
        sentAt: serializeTime || null,
        resolvedAt: serializeTime || null,
        createdAt: serializeTime,
        updatedAt: serializeTime,
      })(alert),
    }),
  }
);

const deviceConfigurationSchema = new schema.Entity(
  'deviceConfigurations',
  {},
  {
    processStrategy: createSerializer({
      timestamp: serializeTime,
    }),
  }
);

const deviceLogSchema = new schema.Entity(
  'logs',
  {},
  {
    processStrategy: createSerializer({
      timestamp: serializeTime,
    }),
  }
);

const devicePendingMessageSchema = new schema.Entity(
  'pendingMessages',
  {},
  {
    processStrategy: createSerializer({
      timestamp: serializeTime,
    }),
  }
);

const heatDisinfectionCycleSchema = new schema.Entity(
  'heatDisinfectionCycles',
  {},
  {
    processStrategy: createSerializer({
      start: serializeTime,
      stop: serializeTime,
      createdAt: serializeTime,
      updatedAt: serializeTime,
    }),
  }
);

export const GET_DEVICE_REQUEST = 'GET_DEVICE_REQUEST';
export const GET_DEVICE_SUCCESS = 'GET_DEVICE_SUCCESS';
export const GET_DEVICE_FAILURE = 'GET_DEVICE_FAILURE';

export const getDevice = (id: string): NetworkActionT => ({
  CALL_API: {
    types: [GET_DEVICE_REQUEST, GET_DEVICE_SUCCESS, GET_DEVICE_FAILURE],
    url: `devices/${id}`,
    httpMethod: 'GET',
    normalizeSchema: { device: deviceSchema },
  },
});

export const GET_DEVICE_MODELS_REQUEST = 'GET_DEVICE_MODELS_REQUEST';
export const GET_DEVICE_MODELS_SUCCESS = 'GET_DEVICE_MODELS_SUCCESS';
export const GET_DEVICE_MODELS_FAILURE = 'GET_DEVICE_MODELS_FAILURE';

export const getDeviceModels = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_MODELS_REQUEST,
      GET_DEVICE_MODELS_SUCCESS,
      GET_DEVICE_MODELS_FAILURE,
    ],
    url: `device_models`,
    httpMethod: 'GET',
  },
});

export const GET_DEVICE_ALERTS_REQUEST = 'GET_DEVICE_ALERTS_REQUEST';
export const GET_DEVICE_ALERTS_SUCCESS = 'GET_DEVICE_ALERTS_SUCCESS';
export const GET_DEVICE_ALERTS_FAILURE = 'GET_DEVICE_ALERTS_FAILURE';

export const getDeviceAlerts = (
  deviceId: string,
  page: number
): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_ALERTS_REQUEST,
      GET_DEVICE_ALERTS_SUCCESS,
      GET_DEVICE_ALERTS_FAILURE,
    ],
    url: `/devices/${deviceId}/alerts`,
    httpMethod: 'GET',
    params: { page, pageSize: 30 },
    actionPayload: { deviceId },
    normalizeSchema: { data: [deviceAlertSchema] },
  },
});

export const GET_DEVICE_CONFIGURATIONS_REQUEST =
  'GET_DEVICE_CONFIGURATIONS_REQUEST';
export const GET_DEVICE_CONFIGURATIONS_SUCCESS =
  'GET_DEVICE_CONFIGURATIONS_SUCCESS';
export const GET_DEVICE_CONFIGURATIONS_FAILURE =
  'GET_DEVICE_CONFIGURATIONS_FAILURE';

type GetDeviceConfigurationArgsT = {
  page: number,
  deviceId: string,
};

export const getDeviceConfigurations = ({
  page,
  deviceId,
}: GetDeviceConfigurationArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_CONFIGURATIONS_REQUEST,
      GET_DEVICE_CONFIGURATIONS_SUCCESS,
      GET_DEVICE_CONFIGURATIONS_FAILURE,
    ],
    url: `devices/${deviceId}/configurations`,
    httpMethod: 'GET',
    params: {
      page,
      pageSize: 30,
    },
    actionPayload: { deviceId },
    normalizeSchema: { data: [deviceConfigurationSchema] },
  },
});

export const GET_DEVICE_DATA_REQUEST = 'GET_DEVICE_DATA_REQUEST';
export const GET_DEVICE_DATA_SUCCESS = 'GET_DEVICE_DATA_SUCCESS';
export const GET_DEVICE_DATA_FAILURE = 'GET_DEVICE_DATA_FAILURE';

export type GetDeviceDataArgsT = {
  deviceId: string,
  period?: string,
  dateTime?: string,
};

export const getDeviceData = ({
  deviceId,
  period,
  dateTime,
}: GetDeviceDataArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_DATA_REQUEST,
      GET_DEVICE_DATA_SUCCESS,
      GET_DEVICE_DATA_FAILURE,
    ],
    url: `/device_data/${deviceId}/measurements`,
    httpMethod: 'GET',
    params: {
      device: {
        period: period || 'hourly',
        dateTime,
      },
    },
    actionPayload: { deviceId },
  },
});

export const GET_DEVICE_DATA_INTERVALS_REQUEST =
  'GET_DEVICE_DATA_INTERVALS_REQUEST';
export const GET_DEVICE_DATA_INTERVALS_SUCCESS =
  'GET_DEVICE_DATA_INTERVALS_SUCCESS';
export const GET_DEVICE_DATA_INTERVALS_FAILURE =
  'GET_DEVICE_DATA_INTERVALS_FAILURE';

export const getDeviceDataIntervals = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_DATA_INTERVALS_REQUEST,
      GET_DEVICE_DATA_INTERVALS_SUCCESS,
      GET_DEVICE_DATA_INTERVALS_FAILURE,
    ],
    url: '/device_data/measurement_intervals',
    httpMethod: 'GET',
  },
});

export const GET_DEVICE_INFO_REQUEST = 'GET_DEVICE_INFO_REQUEST';
export const GET_DEVICE_INFO_SUCCESS = 'GET_DEVICE_INFO_SUCCESS';
export const GET_DEVICE_INFO_FAILURE = 'GET_DEVICE_INFO_FAILURE';

export const getDeviceInfo = (id: string): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_INFO_REQUEST,
      GET_DEVICE_INFO_SUCCESS,
      GET_DEVICE_INFO_FAILURE,
    ],
    url: `device_data/${id}/info`,
    httpMethod: 'GET',
    normalizeSchema: { deviceData: dashboardDeviceSchema },
  },
});

export const GET_DEVICE_LOGS_REQUEST = 'GET_DEVICE_LOGS_REQUEST';
export const GET_DEVICE_LOGS_SUCCESS = 'GET_DEVICE_LOGS_SUCCESS';
export const GET_DEVICE_LOGS_FAILURE = 'GET_DEVICE_LOGS_FAILURE';

export const getDeviceLogs = (
  deviceId: string,
  page: number
): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_LOGS_REQUEST,
      GET_DEVICE_LOGS_SUCCESS,
      GET_DEVICE_LOGS_FAILURE,
    ],
    url: `/devices/${deviceId}/logs`,
    httpMethod: 'GET',
    params: { page, pageSize: 30 },
    actionPayload: { deviceId, page },
    normalizeSchema: { data: [deviceLogSchema] },
  },
});

export const GET_DEVICE_PENDING_MESSAGES_REQUEST =
  'GET_DEVICE_PENDING_MESSAGES_REQUEST';
export const GET_DEVICE_PENDING_MESSAGES_SUCCESS =
  'GET_DEVICE_PENDING_MESSAGES_SUCCESS';
export const GET_DEVICE_PENDING_MESSAGES_FAILURE =
  'GET_DEVICE_PENDING_MESSAGES_FAILURE';

export const getDevicePendingMessages = (
  deviceId: string,
  page: number
): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_PENDING_MESSAGES_REQUEST,
      GET_DEVICE_PENDING_MESSAGES_SUCCESS,
      GET_DEVICE_PENDING_MESSAGES_FAILURE,
    ],
    url: `/devices/${deviceId}/pending_messages_history`,
    httpMethod: 'GET',
    params: { page },
    actionPayload: { deviceId, page },
    normalizeSchema: { 'device/pendingMessages': devicePendingMessageSchema },
  },
});

export const GET_DEVICES_REQUEST = 'GET_DEVICES_REQUEST';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILURE = 'GET_DEVICES_FAILURE';

type GetDevicesArgsT = {
  page: number,
  centerId?: ?string,
};

export const getDevices = ({
  page,
  centerId,
}: GetDevicesArgsT): NetworkActionT => ({
  CALL_API: {
    types: [GET_DEVICES_REQUEST, GET_DEVICES_SUCCESS, GET_DEVICES_FAILURE],
    url: `devices`,
    httpMethod: 'GET',
    params: {
      page,
      pageSize: 30,
      centerId,
    },
    normalizeSchema: { data: [deviceSchema] },
  },
});

export const GET_DEVICES_DASHBOARD_REQUEST = 'GET_DEVICES_DASHBOARD_REQUEST';
export const GET_DEVICES_DASHBOARD_SUCCESS = 'GET_DEVICES_DASHBOARD_SUCCESS';
export const GET_DEVICES_DASHBOARD_FAILURE = 'GET_DEVICES_DASHBOARD_FAILURE';

type GetDevicesDashboardArgsT = Object;

export const getDevicesDashboard = (
  args?: GetDevicesDashboardArgsT
): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICES_DASHBOARD_REQUEST,
      GET_DEVICES_DASHBOARD_SUCCESS,
      GET_DEVICES_DASHBOARD_FAILURE,
    ],
    url: `device_data/summary`,
    params: {
      ...args,
    },
    httpMethod: 'GET',
    normalizeSchema: {
      'deviceData/dashboard': {
        tenants: [deviceDashboardTenantsSchema],
        filters: [deviceDashboardFiltersSchema],
      },
    },
  },
});

export const GET_HEAT_DISINFECTION_CYCLES_REQUEST =
  'GET_HEAT_DISINFECTION_CYCLES_REQUEST';
export const GET_HEAT_DISINFECTION_CYCLES_SUCCESS =
  'GET_HEAT_DISINFECTION_CYCLES_SUCCESS';
export const GET_HEAT_DISINFECTION_CYCLES_FAILURE =
  'GET_HEAT_DISINFECTION_CYCLES_FAILURE';

const PAGE_SIZE = 30;

export const getHeatDisinfectionCycles = (
  deviceId: string,
  page: number
): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_HEAT_DISINFECTION_CYCLES_REQUEST,
      GET_HEAT_DISINFECTION_CYCLES_SUCCESS,
      GET_HEAT_DISINFECTION_CYCLES_FAILURE,
    ],
    url: `/devices/${deviceId}/heat_disinfections`,
    httpMethod: 'GET',
    params: { page, pageSize: PAGE_SIZE },
    actionPayload: { deviceId },
    normalizeSchema: { data: [heatDisinfectionCycleSchema] },
  },
});

export const GET_HEAT_DISINFECTION_DATA_REQUEST =
  'GET_HEAT_DISINFECTION_DATA_REQUEST';
export const GET_HEAT_DISINFECTION_DATA_SUCCESS =
  'GET_HEAT_DISINFECTION_DATA_SUCCESS';
export const GET_HEAT_DISINFECTION_DATA_FAILURE =
  'GET_HEAT_DISINFECTION_DATA_FAILURE';

export const getHeatDisinfectionData = (cycleId: string): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_HEAT_DISINFECTION_DATA_REQUEST,
      GET_HEAT_DISINFECTION_DATA_SUCCESS,
      GET_HEAT_DISINFECTION_DATA_FAILURE,
    ],
    url: `/heat_disinfections/${cycleId}/data`,
    httpMethod: 'GET',
    actionPayload: { cycleId },
  },
});

export const ADD_DEVICE_REQUEST = 'ADD_DEVICE_REQUEST';
export const ADD_DEVICE_SUCCESS = 'ADD_DEVICE_SUCCESS';
export const ADD_DEVICE_FAILURE = 'ADD_DEVICE_FAILURE';

export type AddDeviceArgsT = {
  name: string,
  modelIdentifier: string,
  serialNumber: string,
  centerId: string,
};

export const addDevice = ({
  name,
  modelIdentifier,
  serialNumber,
  centerId,
}: AddDeviceArgsT): NetworkActionT => ({
  CALL_API: {
    types: [ADD_DEVICE_REQUEST, ADD_DEVICE_SUCCESS, ADD_DEVICE_FAILURE],
    url: 'devices',
    httpMethod: 'POST',
    payload: {
      device: {
        name,
        modelIdentifier,
        serialNumber,
        centerId,
      },
    },
    normalizeSchema: { device: deviceSchema },
  },
});

export const UPDATE_DEVICE_REQUEST = 'UPDATE_DEVICE_REQUEST';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAILURE = 'UPDATE_DEVICE_FAILURE';

export type UpdateDeviceArgsT = {
  id: string,
  name: string,
  centerId: string,
};

export const updateDevice = ({
  id,
  name,
  centerId,
}: UpdateDeviceArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_DEVICE_REQUEST,
      UPDATE_DEVICE_SUCCESS,
      UPDATE_DEVICE_FAILURE,
    ],
    url: `devices/${id}`,
    httpMethod: 'PUT',
    payload: {
      device: {
        name,
        centerId,
      },
    },
    normalizeSchema: { device: deviceSchema },
  },
});

export const DELETE_DEVICE_REQUEST = 'DELETE_DEVICE_REQUEST';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAILURE = 'DELETE_DEVICE_FAILURE';

export const deleteDevice = (id: string): NetworkActionT => ({
  CALL_API: {
    types: [
      DELETE_DEVICE_REQUEST,
      DELETE_DEVICE_SUCCESS,
      DELETE_DEVICE_FAILURE,
    ],
    url: `devices/${id}`,
    httpMethod: 'DELETE',
    actionPayload: { id },
  },
});

export const RESOLVE_DEVICE_ALERTS_REQUEST = 'RESOLVE_DEVICE_ALERTS_REQUEST';
export const RESOLVE_DEVICE_ALERTS_SUCCESS = 'RESOLVE_DEVICE_ALERTS_SUCCESS';
export const RESOLVE_DEVICE_ALERTS_FAILURE = 'RESOLVE_DEVICE_ALERTS_FAILURE';

export const resolveDeviceAlerts = (deviceId: string): NetworkActionT => ({
  CALL_API: {
    types: [
      RESOLVE_DEVICE_ALERTS_REQUEST,
      RESOLVE_DEVICE_ALERTS_SUCCESS,
      RESOLVE_DEVICE_ALERTS_FAILURE,
    ],
    url: `devices/${deviceId}/alerts/resolve`,
    httpMethod: 'PUT',
    normalizeSchema: { 'device/alerts': [deviceAlertSchema] },
  },
});

export const SEARCH_DEVICES_REQUEST = 'SEARCH_DEVICES_REQUEST';
export const SEARCH_DEVICES_SUCCESS = 'SEARCH_DEVICES_SUCCESS';
export const SEARCH_DEVICES_FAILURE = 'SEARCH_DEVICES_FAILURE';

export type SearchDevicesArgsT = {
  centerId: string,
  query: string,
};

export const searchDevices = ({
  centerId,
  query,
}: SearchDevicesArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      SEARCH_DEVICES_REQUEST,
      SEARCH_DEVICES_SUCCESS,
      SEARCH_DEVICES_FAILURE,
    ],
    url: `devices/search`,
    params: {
      centerId,
      query,
    },
    httpMethod: 'GET',
    actionPayload: {
      centerId,
      query,
    },
    normalizeSchema: {
      deviceOptions: [deviceSchema],
    },
  },
});
