// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';

import { currentUser as currentUserSelector } from 'reducers/auth';

export function featureCheck({
  currentUser,
  rights,
  booleanMatch = true,
}: {
  currentUser?: ?UserT,
  rights?: Array<string>,
  booleanMatch?: boolean,
}) {
  if (!currentUser && Array.isArray(rights)) {
    return false;
  }

  const user = currentUser || { rights: [] };
  const availableRights = user.rights.map((right) => right.name);

  const someRightsMatch = Array.isArray(rights)
    ? rights.some((right) => availableRights.includes(right))
    : true;

  return booleanMatch && someRightsMatch;
}

function FeatureFlag({
  rights,
  booleanMatch,
  children,
  ...extra
}: {
  rights?: Array<string>,
  booleanMatch?: boolean,
  children?: React.Node | ((boolean) => ?React.Node),
}) {
  const currentUser = useSelector(currentUserSelector);

  const shouldRender = featureCheck({
    currentUser,
    rights,
    booleanMatch,
  });

  let nodes = [];

  if (typeof children === 'function') {
    nodes = children(shouldRender);
  } else if (shouldRender) {
    nodes = children;
  }

  return React.Children.map(nodes, (child) => React.cloneElement(child, extra));
}

export default FeatureFlag;
