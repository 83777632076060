// @flow

import jwtDecode from 'jwt-decode';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  REFRESH_TOKEN_REQUEST,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAILURE,
  RESET_AUTH_ERROR,
} from '../actions/auth';
import { GET_CURRENT_USER_SUCCESS } from '../actions/users';
import {
  UNAUTHORIZED,
  QUEUE_NETWORK_ACTION,
  CLEAR_QUEUED_NETWORK_ACTIONS,
} from '../actions/network';

type State = {
  isFetching: boolean,
  error: ?Object,
  access: ?string,
  refresh: ?string,
  currentUser: ?UserT,
  queuedNetworkActions: Array<NetworkActionT>,
};

const initialState = {
  isFetching: false,
  error: null,
  access: null,
  refresh: null,
  currentUser: null,
  queuedNetworkActions: [],
};

export default (state: State = initialState, action: Object): State => {
  switch (action.type) {
    case UNAUTHORIZED:
    case LOGOUT_SUCCESS:
    case REFRESH_TOKEN_FAILURE:
      return initialState;

    case LOGIN_REQUEST:
    case LOGOUT_REQUEST:
    case REFRESH_TOKEN_REQUEST:
      return { ...state, isFetching: true };

    case LOGIN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        access: action.payload.access,
        refresh: action.payload.refresh,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload.message,
      };
    case GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: action.payload.response.result.user,
      };

    case REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        access: action.payload.response.access,
      };

    case QUEUE_NETWORK_ACTION:
      return {
        ...state,
        queuedNetworkActions: [...state.queuedNetworkActions, action.payload],
      };

    case CLEAR_QUEUED_NETWORK_ACTIONS:
      return {
        ...state,
        queuedNetworkActions: [],
      };

    case RESET_AUTH_ERROR:
      return {
        ...state,
        error: undefined,
      };

    default:
      return state;
  }
};

type JWT = {
  exp: number,
  iat: number,
  nbf: number,
};

const isValid = (jwt: JWT): boolean => {
  const secondsSinceEpoch = Math.floor(Date.now() / 1000);
  return secondsSinceEpoch < jwt.exp;
};

export const authenticated = ({
  access,
  refresh,
}: {
  access: ?string,
  refresh: ?string,
}): boolean => {
  if (access === null || refresh === null) return false;

  try {
    const jwtToken: JWT = jwtDecode(access);
    const jwtRefresh: JWT = jwtDecode(refresh);

    return isValid(jwtToken) || isValid(jwtRefresh);
  } catch (error) {
    return false;
  }
};

export const currentUser = (state: Object): ?UserT => {
  const currentUserID = state.auth.currentUser;

  if (!currentUserID) {
    return null;
  }

  return state.entities.getIn(['users', 'byId', currentUserID]).toJS();
};

export const authSelector = (
  state: Object
): { access: string, refresh: string } => state.auth;

export const authenticatedSelector = (state: { auth: State }): boolean =>
  authenticated(state.auth);
