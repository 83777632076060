// @flow

import { fromJS } from 'immutable';

import { SEARCH_DEVICES_SUCCESS } from 'actions/devices';

const BY_CENTER_ID_PATH = ['centers'];
const BY_DEVICE_ID_PATH = ['devices', 'byId'];
const BY_DEVICE_SEARCH_RESULTS_PATH = ['devices', 'searchResults'];
const BY_DEVICE_LAST_SEARCH_TERM_PATH = ['devices', 'lastSearchTerm'];

export default {
  [SEARCH_DEVICES_SUCCESS]: (state: any, action: any): any => {
    const {
      query,
      response: {
        entities: { centers, devices },
      },
    } = action.payload;

    const deviceOptionsArr = Object.keys(devices || {}).map((key) => ({
      ...devices[key],
    }));

    return state
      .mergeIn(BY_CENTER_ID_PATH, fromJS(centers || {}))
      .mergeIn(BY_DEVICE_ID_PATH, fromJS(devices || {}))
      .mergeIn(BY_DEVICE_SEARCH_RESULTS_PATH, {
        [query]: deviceOptionsArr,
      })
      .setIn(BY_DEVICE_LAST_SEARCH_TERM_PATH, query);
  },
};

export const deviceSearchResultsSelector = (state: any): ?Array<OptionT> => {
  const {
    devices: { searchResults, lastSearchTerm },
  } = state.entities.toJS();

  return searchResults && lastSearchTerm ? searchResults[lastSearchTerm] : [];
};
