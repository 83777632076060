// @flow

import * as React from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import setLocation from 'actions/router';

const RouteWatcher = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(setLocation(window.location));
  }, [dispatch, location]);

  return null;
};

export default RouteWatcher;
