// @flow

import { fromJS } from 'immutable';

import {
  GET_USERS_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  ADD_USER_SUCCESS,
  UPDATE_CURRENT_USER_SUCCESS,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_ROLE_SUCCESS,
  DELETE_USER_SUCCESS,
} from 'actions/users';

import fullName from 'utils/fullname';
import deindex from 'utils/deindex';

const BY_ID_PATH = ['users', 'byId'];
const BY_RESULT_PATH = ['users', 'byResult'];

const mergeUsersHandler = (state: any, action: Object) => {
  const {
    response: {
      entities: { users },
      result: { data },
    },
  } = action.payload;

  return state
    .mergeDeepIn(BY_ID_PATH, fromJS(users))
    .setIn(BY_RESULT_PATH, fromJS(data || []));
};

const mergeUserHandler = (state: any, action: Object) => {
  const {
    response: {
      entities: { users },
    },
  } = action.payload;

  return state.mergeDeepIn(BY_ID_PATH, fromJS(users));
};

export default {
  [GET_USERS_SUCCESS]: mergeUsersHandler,
  [GET_CURRENT_USER_SUCCESS]: mergeUserHandler,
  [UPDATE_CURRENT_USER_SUCCESS]: mergeUserHandler,
  [UPDATE_USER_SUCCESS]: mergeUserHandler,
  [UPDATE_USER_ROLE_SUCCESS]: mergeUserHandler,
  [ADD_USER_SUCCESS]: (state: any, action: Object) => {
    const {
      response: {
        entities: { users },
        result: { user },
      },
    } = action.payload;

    const currentIds = state.getIn(BY_RESULT_PATH).toJS();

    return state
      .mergeDeepIn(BY_ID_PATH, fromJS(users))
      .setIn(BY_RESULT_PATH, fromJS([...currentIds, user]));
  },
  [DELETE_USER_SUCCESS]: (state: any, action: Object) => {
    const {
      request: { id: userId },
    } = action.payload;

    const newIds = state
      .getIn(BY_RESULT_PATH)
      .toJS()
      .filter((id) => id !== userId);

    return state
      .removeIn([...BY_ID_PATH, userId])
      .setIn(BY_RESULT_PATH, fromJS(newIds || []));
  },
};

export const userSelector = (state: any, id: string): ?UserT => {
  const {
    users: { byId: usersById },
  } = state.entities.toJS();

  if (!usersById[id]) return undefined;

  return usersById[id];
};

export const usersSelector = (state: any): ?Array<UserT> => {
  const {
    users: { byId: usersById },
  } = state.entities.toJS();

  if (!usersById) return undefined;

  return deindex(usersById).filter((user) => !user.deletedAt);
};

export const usersPaginatedSelector = (state: any): ?Array<UserT> => {
  const isFetching = !!(
    state.network.GET_USERS || state.network.GET_USERS === undefined
  );

  if (isFetching) return undefined;

  const {
    users: { byId: usersById, byResult: usersIdsByResult },
  } = state.entities.toJS();

  if (!usersById || !usersIdsByResult) return undefined;

  return usersIdsByResult.reduce(
    (arr, id) => (usersById[id] ? [...arr, usersById[id]] : arr),
    []
  );
};

export const userOptionsSelector = (
  state: any,
  filter?: Object
): ?Array<OptionT> => {
  const users = usersSelector(state);

  if (!users) return undefined;

  const filteredUsers = filter
    ? Object.keys(filter).reduce((acc, key) => {
        return acc.filter((u) => u[key] === filter[key]);
      }, users)
    : users;

  return filteredUsers.map((u) => ({ value: u.id, label: fullName({ ...u }) }));
};
