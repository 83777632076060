// @flow

import * as React from 'react';
import { IntlProvider } from 'react-intl';
import translations from 'translations';

const LOCALE = process.env.REACT_APP_LOCALE || 'en-GB';
const LANGUAGE = LOCALE.split('-')[0];

const LocaleProvider = ({ children }: { children: React.Node }) => {
  const messages = translations[LANGUAGE]
    ? translations[LANGUAGE].messages
    : {};

  return (
    <IntlProvider locale={LOCALE} key={LOCALE} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default LocaleProvider;
