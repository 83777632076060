// @flow

export const LOCATION_CHANGE = 'LOCATION_CHANGE';

export default (location: Object) => ({
  type: LOCATION_CHANGE,
  payload: {
    location,
  },
});
