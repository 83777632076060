// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';

import { currentUser } from 'reducers/auth';

import UserInfo from 'components/UserInfo';

const t = defineMessages({
  accountTitle: { id: 'navbar_accountTitle', defaultMessage: 'My account' },
});

type Props = {
  perspective: string,
  user: UserT,
};

const NavbarCurrentUser = ({ perspective, user }: Props) => {
  const { formatMessage } = useIntl();

  if (!user) {
    return null;
  }

  return (
    <NavLink
      to={`/${perspective}/profile`}
      title={formatMessage(t.accountTitle)}
      activeClassName="is-active"
      className="navbar-account"
    >
      <UserInfo user={user} />
    </NavLink>
  );
};

const mapStateToProps = (state) => {
  const user = currentUser(state);

  return { user };
};

export default connect(mapStateToProps)(NavbarCurrentUser);
