// @flow

import { schema } from 'normalizr';

import createSerializer, { serializeTime } from 'utils/schemaSerializers';

export const connectorSchema = new schema.Entity('connectors', {
  processStrategy: createSerializer({
    createdAt: serializeTime,
    updatedAt: serializeTime,
  }),
});

export const GET_CONNECTORS_REQUEST = 'GET_CONNECTORS_REQUEST';
export const GET_CONNECTORS_SUCCESS = 'GET_CONNECTORS_SUCCESS';
export const GET_CONNECTORS_FAILURE = 'GET_CONNECTORS_FAILURE';

export const getConnectors = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_CONNECTORS_REQUEST,
      GET_CONNECTORS_SUCCESS,
      GET_CONNECTORS_FAILURE,
    ],
    url: `connectors`,

    httpMethod: 'GET',
    normalizeSchema: { connectors: [connectorSchema] },
  },
});

export const UPDATE_CONNECTOR_REQUEST = 'UPDATE_CONNECTOR_REQUEST';
export const UPDATE_CONNECTOR_SUCCESS = 'UPDATE_CONNECTOR_SUCCESS';
export const UPDATE_CONNECTOR_FAILURE = 'UPDATE_CONNECTOR_FAILURE';

export type UpdateConnectorArgsT = {
  id: string,
  name: string,
  pollingInterval: number,
  retryDelay: number,
};

export const updateConnector = ({
  id,
  name,
  pollingInterval,
  retryDelay,
}: UpdateConnectorArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CONNECTOR_REQUEST,
      UPDATE_CONNECTOR_SUCCESS,
      UPDATE_CONNECTOR_FAILURE,
    ],
    url: `connectors/${id}`,
    httpMethod: 'PUT',
    payload: {
      connector: {
        name,
        pollingInterval,
        retryDelay,
      },
    },
    normalizeSchema: { connector: connectorSchema },
  },
});

export const DELETE_CONNECTOR_REQUEST = 'DELETE_CONNECTOR_REQUEST';
export const DELETE_CONNECTOR_SUCCESS = 'DELETE_CONNECTOR_SUCCESS';
export const DELETE_CONNECTOR_FAILURE = 'DELETE_CONNECTOR_FAILURE';

export const deleteConnector = (id: string): NetworkActionT => ({
  CALL_API: {
    types: [
      DELETE_CONNECTOR_REQUEST,
      DELETE_CONNECTOR_SUCCESS,
      DELETE_CONNECTOR_FAILURE,
    ],
    url: `connectors/${id}`,
    httpMethod: 'DELETE',
    actionPayload: { id },
  },
});

export const GET_TOKEN_REQUEST = 'GET_TOKEN_REQUEST';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_FAILURE = 'GET_TOKEN_FAILURE';

export const getToken = (): NetworkActionT => ({
  CALL_API: {
    types: [GET_TOKEN_REQUEST, GET_TOKEN_SUCCESS, GET_TOKEN_FAILURE],
    url: `connectors/token`,
    httpMethod: 'GET',
  },
});
