// @flow

import * as React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import configureStore from '../configureStore';

import Loading from '../components/Loading';
import ErrorBoundary from '../components/ErrorBoundary';
import Configuration from '../components/Configuration';

import App from './App';
import Login from './login';
import LocaleProvider from './LocaleProvider';
import RouteWatcher from './RouteWatcher';
import Error from './Error';
import Forbidden from './Forbidden';
import NotFound from './NotFound';

const { store, persistor } = configureStore();

const Root = () => (
  <Provider store={store}>
    <PersistGate loading={<Loading />} persistor={persistor}>
      <Configuration>
        <LocaleProvider>
          <ErrorBoundary>
            <BrowserRouter>
              <>
                <RouteWatcher />

                <Switch>
                  <Route path="/login">
                    <Login />
                  </Route>

                  <Route path="/error">
                    <Error />
                  </Route>

                  <Route path="/forbidden">
                    <Forbidden />
                  </Route>

                  <Route path="/not_found">
                    <NotFound />
                  </Route>

                  <Route path="/">
                    <App />
                  </Route>
                </Switch>

                <ToastContainer />
              </>
            </BrowserRouter>
          </ErrorBoundary>
        </LocaleProvider>
      </Configuration>
    </PersistGate>
  </Provider>
);

export default Root;
