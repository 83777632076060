// @flow
import type { Dispatch } from 'redux';

import { authenticator } from '../authenticator';
import type { Credentials } from '../authenticator';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const loginAction =
  (options: Credentials) => async (dispatch: Dispatch<Object>) => {
    dispatch({ type: LOGIN_REQUEST, payload: null, error: false });

    try {
      const { access, refresh } = await authenticator.login(options);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: { access, refresh },
        error: false,
      });
    } catch (e) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: { message: e.message },
        error: true,
      });
    }
  };

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const logoutAction = () => async (dispatch: Dispatch<Object>) => {
  await dispatch({
    CALL_API: {
      types: [LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGOUT_FAILURE],
      url: 'auth',
      httpMethod: 'DELETE',
    },
  });

  authenticator.logout();
};

export const REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE';

export const refreshAction = (): NetworkActionT => ({
  CALL_API: {
    types: [
      REFRESH_TOKEN_REQUEST,
      REFRESH_TOKEN_SUCCESS,
      REFRESH_TOKEN_FAILURE,
    ],
    url: 'auth/refresh',
    httpMethod: 'POST',
  },
});

export const RESET_AUTH_ERROR = 'RESET_AUTH_ERROR';

export const resetAuthErrorAction = () => ({ type: RESET_AUTH_ERROR });
