// @flow

import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import { IT_ADMIN, TENANT_ADMIN, VIEW_USERS, APP_ADMIN } from 'rights';
import FeatureFlag from 'components/FeatureFlag';

const t = defineMessages({
  settings: {
    id: 'admin_navigation_settings',
    defaultMessage: 'Settings',
  },
  iam: {
    id: 'admin_settings_navigation_iam',
    defaultMessage: 'Identity & Access',
  },
  infrastructure: {
    id: 'admin_settings_navigation_infrastructure',
    defaultMessage: 'Infrastructure',
  },
  devices: {
    id: 'admin_settings_navigation_devices',
    defaultMessage: 'Device Management',
  },
  system: {
    id: 'admin_settings_navigation_system',
    defaultMessage: 'System Configuration',
  },
});

const Navigation = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <li role="presentation">
        <FeatureFlag rights={[IT_ADMIN, TENANT_ADMIN, VIEW_USERS]}>
          <NavLink
            to="/admin/iam"
            activeClassName="is-active"
            title={formatMessage(t.iam)}
          >
            <i className="material-icons">account_box</i>
          </NavLink>
        </FeatureFlag>

        <NavLink
          to="/admin/infrastructure"
          activeClassName="is-active"
          title={formatMessage(t.infrastructure)}
        >
          <i className="material-icons">business</i>
        </NavLink>

        <NavLink
          to="/admin/devices"
          activeClassName="is-active"
          title={formatMessage(t.devices)}
        >
          <i className="material-icons">devices</i>
        </NavLink>

        <FeatureFlag rights={[IT_ADMIN, APP_ADMIN]}>
          <NavLink
            to="/admin/system"
            activeClassName="is-active"
            title={formatMessage(t.system)}
          >
            <i className="material-icons">settings</i>
          </NavLink>
        </FeatureFlag>
      </li>
    </>
  );
};

export default Navigation;
