// @flow

import { fromJS } from 'immutable';

import { serializeTime } from 'utils/schemaSerializers';

import {
  GET_TOKEN_SUCCESS,
  GET_CONNECTORS_SUCCESS,
  UPDATE_CONNECTOR_SUCCESS,
  DELETE_CONNECTOR_SUCCESS,
} from 'actions/connectors';

import deindex from 'utils/deindex';

import devices from './devices';

const CONNECTOR_PATH = ['connectors'];

const TOKEN_PATH = ['connectorToken'];

export default {
  [GET_TOKEN_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { token, validUntil },
    } = action.payload;

    return state.setIn(
      TOKEN_PATH,
      fromJS(token ? { token, validUntil: serializeTime(validUntil) } : null)
    );
  },
  [GET_CONNECTORS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { connectors },
      },
    } = action.payload;

    return state.setIn(CONNECTOR_PATH, fromJS(connectors || {}));
  },
  [UPDATE_CONNECTOR_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { connectors },
      },
    } = action.payload;

    return state.mergeIn(CONNECTOR_PATH, fromJS(connectors));
  },
  [DELETE_CONNECTOR_SUCCESS]: (state: any, action: any): any => {
    const { id } = action.payload;

    return state.deleteIn([...CONNECTOR_PATH, id]);
  },
  ...devices,
};

export const connectorTokenSelector = (state: any): ?ConnectorTokenT => {
  const { connectorToken } = state.entities.toJS();

  if (!connectorToken) return;

  return connectorToken;
};

export const connectorsSelector = (state: any): ?Array<ConnectorT> => {
  const { connectors } = state.entities.toJS();

  if (!connectors) return undefined;

  return deindex(connectors);
};

export const connectorSelector = (
  state: any,
  id: ?string
): ?Array<ConnectorT> => {
  const { connectors } = state.entities.toJS();

  if (!connectors) return undefined;

  const connector = connectors[id];

  if (!connector) return undefined;

  return connector;
};
