// @flow

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';

import Modal from 'components/Modal';
import Section from 'components/Section';
import Flash from 'components/Flash';
import DataList from 'components/DataList';

const t = defineMessages({
  title: {
    id: 'browser_support_modal_title',
    defaultMessage: 'Browser Support',
  },
  description: {
    id: 'browser_support_modal_description',
    defaultMessage:
      'Dear customer, Your current browser does not provide the optimal user experience! We recommend the use of one of the following browsers in the list below.',
  },
  supportFor: {
    id: 'browser_support_modal_support_for',
    defaultMessage: 'Last {number} major version(s)',
  },
});

type Props = {
  show: boolean,
  cancel: () => void,
};

const UnsupportedBrowserModal = ({ show, cancel }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <Modal
      large
      show={show}
      showCancel={false}
      showSubmit={false}
      title={formatMessage(t.title)}
      cancel={cancel}
    >
      <div style={{ textAlign: 'left' }}>
        <Flash primary close={false} message={formatMessage(t.description)} />

        <Section style={{ marginTop: 15 }}>
          <DataList
            values={[
              {
                label: 'Chrome',
                value: formatMessage(t.supportFor, { number: 2 }),
              },
              {
                label: 'Firefox',
                value: formatMessage(t.supportFor, { number: 2 }),
              },
              {
                label: 'Safari',
                value: formatMessage(t.supportFor, { number: 2 }),
              },
              {
                label: 'Edge',
                value: formatMessage(t.supportFor, { number: 2 }),
              },
            ]}
          />
        </Section>
      </div>
    </Modal>
  );
};

export default UnsupportedBrowserModal;
