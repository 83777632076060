// @flow

import { fromJS } from 'immutable';

import {
  GET_CONNECTOR_DEVICE_CONFIGS_SUCCESS,
  ADD_CONNECTOR_DEVICE_CONFIG_SUCCESS,
  UPDATE_CONNECTOR_DEVICE_CONFIG_SUCCESS,
  DELETE_CONNECTOR_DEVICE_CONFIG_SUCCESS,
} from 'actions/connectors/deviceconfigs';

const DEVICES_PATH = ['devices', 'byId'];
const CENTERS_PATH = ['centers'];
const CONNECTOR_DEVICE_CONFIGS_PATH = ['connectorDeviceConfigs'];

export default {
  [GET_CONNECTOR_DEVICE_CONFIGS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { connectorDeviceConfigs, devices, centers },
      },
    } = action.payload;

    return state
      .setIn(
        CONNECTOR_DEVICE_CONFIGS_PATH,
        fromJS(connectorDeviceConfigs || {})
      )
      .mergeIn(CENTERS_PATH, fromJS(centers) || {})
      .mergeIn(DEVICES_PATH, fromJS(devices || {}));
  },
  [ADD_CONNECTOR_DEVICE_CONFIG_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { connectorDeviceConfigs, devices, centers },
      },
    } = action.payload;

    return state
      .mergeIn(CONNECTOR_DEVICE_CONFIGS_PATH, fromJS(connectorDeviceConfigs))
      .mergeIn(CENTERS_PATH, fromJS(centers))
      .mergeIn(DEVICES_PATH, fromJS(devices));
  },
  [UPDATE_CONNECTOR_DEVICE_CONFIG_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { connectorDeviceConfigs },
      },
    } = action.payload;

    return state.mergeIn(
      CONNECTOR_DEVICE_CONFIGS_PATH,
      fromJS(connectorDeviceConfigs)
    );
  },
  [DELETE_CONNECTOR_DEVICE_CONFIG_SUCCESS]: (state: any, action: any): any => {
    const { connectorDeviceConfigId } = action.payload;

    return state.deleteIn([
      ...CONNECTOR_DEVICE_CONFIGS_PATH,
      connectorDeviceConfigId,
    ]);
  },
};

export const connectorDevicesConfigsSelector = (
  state: any,
  connectorId: ?string
): ?Array<ConnectorDeviceConfigT> => {
  const { connectorDeviceConfigs, devices, centers } = state.entities.toJS();

  if (!connectorDeviceConfigs || !devices || !centers) return undefined;

  const normalized = Object.keys(connectorDeviceConfigs).map((key) => {
    const connectorDeviceConfig = connectorDeviceConfigs[key];
    const device = devices.byId[connectorDeviceConfig.device];
    const center = device ? centers[device.center] : {};

    return {
      ...connectorDeviceConfigs[key],
      device: {
        ...device,
        center,
      },
    };
  });

  return normalized.filter((c) => c.connectorId === connectorId);
};
