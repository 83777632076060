// @flow

import { fromJS } from 'immutable';

import {
  GET_CENTERS_SUCCESS,
  ADD_CENTER_SUCCESS,
  UPDATE_CENTER_SUCCESS,
  DELETE_CENTER_SUCCESS,
} from 'actions/centers';
import { UPDATE_CENTER_EMAIL_CONTACT_SUCCESS } from 'actions/centers/emailContacts';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

export default {
  [GET_CENTERS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    const { centers } = entities;

    return state.mergeDeepIn(['centers'], fromJS(centers || {}));
  },
  [ADD_CENTER_SUCCESS]: (state: any, action: any): any => {
    const { response } = action.payload;
    const {
      entities: { centers },
    } = response;

    return state.mergeDeepIn(['centers'], fromJS(centers || {}));
  },
  [UPDATE_CENTER_SUCCESS]: (state: any, action: any): any => {
    const { response } = action.payload;
    const {
      entities: { centers },
    } = response;

    return state.mergeDeepIn(['centers'], fromJS(centers || {}));
  },
  [UPDATE_CENTER_EMAIL_CONTACT_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { centers },
      },
    } = action.payload;

    return state.mergeDeepIn(['centers'], fromJS(centers || {}));
  },
  [DELETE_CENTER_SUCCESS]: (state: any, action: any): any => {
    const { centerId } = action.payload;

    const centers = state.getIn(['centers']).delete(centerId);

    return state.setIn(['centers'], centers);
  },
};

export const centersSelector = (state: any): ?Array<CenterT> => {
  const { centers } = state.entities.toJS();

  if (centers === undefined) return undefined;

  return deindex(centers).sort(sortInline('name', 'ascending'));
};

export const centerOptionsSelector = (state: any): ?Array<OptionT> => {
  const { centers } = state.entities.toJS();

  if (centers === undefined) return undefined;

  return deindex(centers)
    .map(({ id, name }) => ({ label: name, value: id }))
    .sort(sortInline('label'));
};
