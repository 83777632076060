// @flow

import { fromJS } from 'immutable';
import { serializeTime } from 'utils/schemaSerializers';

import {
  GET_DEVICE_DATA_SUCCESS,
  GET_DEVICE_DATA_INTERVALS_SUCCESS,
} from 'actions/devices';

export default {
  [GET_DEVICE_DATA_SUCCESS]: (state: any, action: any): any => {
    const { response, deviceId } = action.payload;

    const deviceData = {
      ...response,
      endTime: serializeTime(response.endTime),
      startTime: serializeTime(response.startTime),
      datapoints: response.datapoints.map((point) => ({
        ...point,
        dateTime: serializeTime(point.dateTime),
        data: point.data.map((d) => ({
          id: d.id,
          value:
            typeof d.value === 'number'
              ? Math.round(d.value * 100) / 100
              : d.value,
        })),
      })),
    };

    return state.setIn(['deviceData', deviceId], fromJS(deviceData || {}));
  },
  [GET_DEVICE_DATA_INTERVALS_SUCCESS]: (state: any, action: any): any => {
    const { response } = action.payload;

    return state.setIn(['deviceIntervals'], fromJS(response || {}));
  },
};

export const deviceDataSelector = (state: any, deviceId: string): ?Object => {
  const { deviceData } = state.entities.toJS();

  return deviceData[deviceId];
};

export const deviceIntervalsSelector = (state: any): ?Object => {
  const { deviceIntervals } = state.entities.toJS();

  if (!deviceIntervals) return {};

  return deviceIntervals;
};

export const deviceIntervalOptionsSelector = (state: any): ?Array<OptionT> => {
  const { deviceIntervals } = state.entities.toJS();

  if (!deviceIntervals) return undefined;

  return Object.keys(deviceIntervals).map((key) => ({
    label: deviceIntervals[key].name,
    value: key,
  }));
};
