// @flow

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { CSSTransition } from 'react-transition-group';

import { defineMessages, FormattedMessage, useIntl } from 'react-intl';

import classNames from 'classnames';

import ActionButton from 'components/ActionButton';

import useModal from 'hooks/useModal';

const t = defineMessages({
  closeTitle: { id: 'modal_close_title', defaultMessage: 'Close' },
  submitTitle: { id: 'modal_submit_title', defaultMessage: 'Submit' },
  cancel: { id: 'modal_cancel_button', defaultMessage: 'Cancel' },
  submit: { id: 'modal_submit_button', defaultMessage: 'Delete' },
});

const ANIMATION_DURATION = 1000;

const Modal = ({
  show,
  showClose = true,
  showCancel = true,
  showSubmit = true,
  small = false,
  large = false,
  extraLarge = false,
  auto = false,
  isCentered = true,
  contentClassName,
  submitButtons: customSubmitButtons,
  cancel,
  submit,
  submitTitle,
  title,
  children,
}: {
  show: boolean,
  showClose?: boolean,
  showCancel?: boolean,
  showSubmit?: boolean,
  small?: boolean,
  large?: boolean,
  extraLarge?: boolean,
  auto?: boolean,
  isCentered?: boolean,
  contentClassName?: string,
  submitButtons?: React.Node,
  cancel: () => void,
  submit?: () => void,
  submitTitle?: string,
  title?: string,
  children?: ?React.Node,
}) => {
  const { formatMessage } = useIntl();

  const modalClasses = classNames('modal', {
    'is-small': small,
    'is-large': large,
    'is-extra-large': extraLarge,
    'is-auto': auto,
  });

  const modalContentClasses = classNames('modal-content', contentClassName, {
    'is-centered': isCentered,
    'without-actions': !showSubmit && !showCancel,
  });

  const shouldRender = useModal(show, ANIMATION_DURATION);

  return ReactDOM.createPortal(
    <CSSTransition in={show} timeout={ANIMATION_DURATION} classNames="modal">
      {show || shouldRender ? (
        <div>
          <div
            className="modal-backdrop"
            role="presentation"
            onClick={cancel}
          />
          <div className={modalClasses}>
            <div className="modal-container">
              <div className="modal-header">
                <h6>{title}</h6>
                {showClose && (
                  <div className="actionbar-header-right">
                    <ActionButton
                      icon="close"
                      title={formatMessage(t.closeTitle)}
                      onClick={cancel}
                    />
                  </div>
                )}
              </div>
              <div className="modal-content-container">
                <div className={modalContentClasses}>{children}</div>
                <div className="modal-footer">
                  {showCancel && (
                    <button type="button" className="button" onClick={cancel}>
                      <FormattedMessage {...t.cancel} />
                    </button>
                  )}
                  {showSubmit &&
                    (customSubmitButtons || (
                      <button
                        type="button"
                        className="button button-danger"
                        onClick={submit}
                      >
                        {submitTitle || <FormattedMessage {...t.submit} />}
                      </button>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </CSSTransition>,
    document.querySelector('#modals')
  );
};

export default Modal;
