// @flow

import { fromJS } from 'immutable';

import {
  GET_HEAT_DISINFECTION_CYCLES_SUCCESS,
  GET_HEAT_DISINFECTION_DATA_SUCCESS,
} from 'actions/devices';

import sortInline from 'utils/sort';
import { serializeTime } from 'utils/schemaSerializers';

const BY_ID = ['heatDisinfectionCycles', 'byId'];
const BY_DEVICE_ID = ['heatDisinfectionCycles', 'byDeviceId'];

export default {
  [GET_HEAT_DISINFECTION_CYCLES_SUCCESS]: (state: any, action: any): any => {
    const {
      deviceId,
      response: {
        entities: { heatDisinfectionCycles },
        result: { data },
      },
    } = action.payload;

    return state
      .mergeIn(BY_ID, fromJS(heatDisinfectionCycles || {}))
      .setIn([...BY_DEVICE_ID, deviceId], fromJS(data || []));
  },
  [GET_HEAT_DISINFECTION_DATA_SUCCESS]: (state: any, action: any): any => {
    const { response, cycleId } = action.payload;

    const heatDisinfectionData = {
      ...response,
      endTime: serializeTime(response.endTime),
      startTime: serializeTime(response.startTime),
      datapoints: response.datapoints.map((point) => ({
        ...point,
        dateTime: serializeTime(point.dateTime),
        data: point.data.map((d) => ({
          id: d.id,
          value:
            typeof d.value === 'number'
              ? Math.round(d.value * 100) / 100
              : d.value,
        })),
      })),
    };

    return state.setIn(
      ['heatDisinfectionData', cycleId],
      fromJS(heatDisinfectionData || {})
    );
  },
};

export const heatDisinfectionCyclesSelector = (
  state: any,
  deviceId: ?string
): ?Array<HeatDisinfectionCycleT> => {
  if (!deviceId) return undefined;

  const {
    heatDisinfectionCycles: { byId, byDeviceId },
  } = state.entities.toJS();

  if (!byDeviceId[deviceId]) return undefined;

  const ids = byDeviceId[deviceId];

  if (!ids) return undefined;

  return ids.map((id) => byId[id]).sort(sortInline('start', 'descending'));
};

export const heatDisinfectionCycleSelector = (
  state: any,
  cycleId: ?string
): ?HeatDisinfectionCycleT => {
  if (!cycleId) return undefined;

  const {
    heatDisinfectionCycles: { byId },
  } = state.entities.toJS();

  return byId[cycleId];
};

export const heatDisinfectionDataSelector = (
  state: any,
  cycleId: ?string
): ?Object => {
  if (!cycleId) return undefined;

  const { heatDisinfectionData } = state.entities.toJS();

  return heatDisinfectionData[cycleId];
};
