// @flow

import React, { useState, useRef } from 'react';
import { useIntl, defineMessages, FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

const t = defineMessages({
  switchTo: {
    id: 'perspective_navigation_switch_to',
    defaultMessage: 'Switch To',
  },
  wts: {
    id: 'perspective_navigation_wts',
    defaultMessage: 'Water Treatment System',
  },
  admin: {
    id: 'perspective_navigation_admin',
    defaultMessage: 'System Settings',
  },
});

const useSideBarMenu = (triggerRef, menuHeight = 0) => {
  const [isOpen, setIsOpen] = useState(false);
  const [bottom, setBottom] = useState(0);

  const augmentedSetIsOpen = (newOpenValue: boolean) => {
    if (newOpenValue) {
      const rect = triggerRef.current?.getBoundingClientRect();
      const wHeight = window.innerHeight;
      setBottom(
        Math.min(
          wHeight - menuHeight,
          Math.max(0, wHeight - (rect?.bottom || 0))
        )
      );
    }
    setIsOpen(newOpenValue);
  };

  return [bottom, isOpen, augmentedSetIsOpen];
};

const PerspectiveNavigation = () => {
  const intl = useIntl();
  const listItem = useRef<HTMLElement | null>(null);
  const [bottom, isOpen, setIsOpen] = useSideBarMenu(listItem, 146);

  const closePerspectivePicker = () => setIsOpen(false);
  const togglePerspectivePicker = () => setIsOpen(!isOpen);

  return (
    <li role="presentation" onClick={togglePerspectivePicker} ref={listItem}>
      <div>
        <i className="material-icons">apps</i>
      </div>

      {isOpen ? (
        <ul
          className="sidebar-menu"
          style={{ bottom }}
          onMouseLeave={closePerspectivePicker}
        >
          <li>
            <h6>
              {intl.formatMessage(t.switchTo)}
              <i
                role="presentation"
                style={{ fontSize: 16, cursor: 'pointer' }}
                className="material-icons float-right"
                onClick={closePerspectivePicker}
              >
                close
              </i>
            </h6>
          </li>

          <li role="presentation">
            <NavLink
              to="/wts"
              title={intl.formatMessage(t.wts)}
              onClick={togglePerspectivePicker}
            >
              <i className="material-icons">invert_colors</i>
              <span>
                <FormattedMessage {...t.wts} />
              </span>
            </NavLink>
          </li>

          <li role="presentation">
            <NavLink
              to="/admin"
              title={intl.formatMessage(t.admin)}
              onClick={togglePerspectivePicker}
            >
              <i className="material-icons">settings</i>
              <span>
                <FormattedMessage {...t.admin} />
              </span>
            </NavLink>
          </li>
        </ul>
      ) : null}
    </li>
  );
};

export default PerspectiveNavigation;
