// @flow

import { schema } from 'normalizr';

import createSerializer, { serializeTime } from 'utils/schemaSerializers';
import { deviceSchema } from 'actions/devices';

const connectorDeviceConfigSchema = new schema.Entity(
  'connectorDeviceConfigs',
  {
    device: deviceSchema,
  },
  {
    processStrategy: createSerializer({
      createdAt: serializeTime,
      updatedAt: serializeTime,
    }),
  }
);

export const GET_CONNECTOR_DEVICE_CONFIGS_REQUEST =
  'GET_CONNECTOR_DEVICE_CONFIGS_REQUEST';
export const GET_CONNECTOR_DEVICE_CONFIGS_SUCCESS =
  'GET_CONNECTOR_DEVICE_CONFIGS_SUCCESS';
export const GET_CONNECTOR_DEVICE_CONFIGS_FAILURE =
  'GET_CONNECTOR_DEVICE_CONFIGS_FAILURE';

const PAGE_SIZE = 30;

export type GetConnectorDeviceConfigsArgsT = {
  page: number,
  connectorId: string,
};

export const getConnectorDeviceConfigs = ({
  page,
  connectorId,
}: GetConnectorDeviceConfigsArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_CONNECTOR_DEVICE_CONFIGS_REQUEST,
      GET_CONNECTOR_DEVICE_CONFIGS_SUCCESS,
      GET_CONNECTOR_DEVICE_CONFIGS_FAILURE,
    ],
    url: `connectors/${connectorId}/devices`,
    params: {
      page,
      pageSize: PAGE_SIZE,
    },
    httpMethod: 'GET',
    normalizeSchema: { data: [connectorDeviceConfigSchema] },
  },
});

export const ADD_CONNECTOR_DEVICE_CONFIG_REQUEST =
  'ADD_CONNECTOR_DEVICE_CONFIG_REQUEST';
export const ADD_CONNECTOR_DEVICE_CONFIG_SUCCESS =
  'ADD_CONNECTOR_DEVICE_CONFIG_SUCCESS';
export const ADD_CONNECTOR_DEVICE_CONFIG_FAILURE =
  'ADD_CONNECTOR_DEVICE_CONFIG_FAILURE';

export type AddConnectorDeviceConfigArgsT = {
  connectorId: string,
  deviceId: string,
  address: string,
  port: string,
  enabled: string,
};

export const addConnectorDeviceConfig = ({
  connectorId,
  deviceId,
  address,
  port,
  enabled,
}: AddConnectorDeviceConfigArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      ADD_CONNECTOR_DEVICE_CONFIG_REQUEST,
      ADD_CONNECTOR_DEVICE_CONFIG_SUCCESS,
      ADD_CONNECTOR_DEVICE_CONFIG_FAILURE,
    ],
    url: `/connectors/${connectorId}/devices`,
    httpMethod: 'POST',
    payload: {
      connectorDevice: {
        connectorId,
        deviceId,
        address,
        port,
        enabled,
      },
    },
    normalizeSchema: { connectorDevice: connectorDeviceConfigSchema },
  },
});

export const UPDATE_CONNECTOR_DEVICE_CONFIG_REQUEST =
  'UPDATE_CONNECTOR_DEVICE_CONFIG_REQUEST';
export const UPDATE_CONNECTOR_DEVICE_CONFIG_SUCCESS =
  'UPDATE_CONNECTOR_DEVICE_CONFIG_SUCCESS';
export const UPDATE_CONNECTOR_DEVICE_CONFIG_FAILURE =
  'UPDATE_CONNECTOR_DEVICE_CONFIG_FAILURE';

export type UpdateConnectorDeviceConfigArgsT = {
  connectorDeviceConfigId: string,
  connectorId: string,
  deviceId: string,
  address: string,
  port: string,
  enabled: string,
};

export const updateConnectorDeviceConfig = ({
  connectorDeviceConfigId,
  connectorId,
  deviceId,
  address,
  port,
  enabled,
}: UpdateConnectorDeviceConfigArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CONNECTOR_DEVICE_CONFIG_REQUEST,
      UPDATE_CONNECTOR_DEVICE_CONFIG_SUCCESS,
      UPDATE_CONNECTOR_DEVICE_CONFIG_FAILURE,
    ],
    url: `connectors/${connectorId}/devices/${connectorDeviceConfigId}`,
    httpMethod: 'PUT',
    payload: {
      connectorDevice: {
        deviceId,
        address,
        port,
        enabled,
      },
    },
    normalizeSchema: { connectorDevice: connectorDeviceConfigSchema },
  },
});

export const DELETE_CONNECTOR_DEVICE_CONFIG_REQUEST =
  'DELETE_CONNECTOR_DEVICE_CONFIG_REQUEST';
export const DELETE_CONNECTOR_DEVICE_CONFIG_SUCCESS =
  'DELETE_CONNECTOR_DEVICE_CONFIG_SUCCESS';
export const DELETE_CONNECTOR_DEVICE_CONFIG_FAILURE =
  'DELETE_CONNECTOR_DEVICE_CONFIG_FAILURE';

export type DeleteConnectorDeviceConfigArgsT = {
  connectorId: string,
  connectorDeviceConfigId: string,
};

export const deleteConnectorDeviceConfig = ({
  connectorId,
  connectorDeviceConfigId,
}: DeleteConnectorDeviceConfigArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      DELETE_CONNECTOR_DEVICE_CONFIG_REQUEST,
      DELETE_CONNECTOR_DEVICE_CONFIG_SUCCESS,
      DELETE_CONNECTOR_DEVICE_CONFIG_FAILURE,
    ],
    url: `connectors/${connectorId}/devices/${connectorDeviceConfigId}`,
    httpMethod: 'DELETE',
    actionPayload: { connectorDeviceConfigId },
  },
});
