// @flow

import { fromJS } from 'immutable';

import {
  GET_DEVICES_SUCCESS,
  GET_DEVICE_SUCCESS,
  ADD_DEVICE_SUCCESS,
  UPDATE_DEVICE_SUCCESS,
  DELETE_DEVICE_SUCCESS,
  GET_DEVICE_MODELS_SUCCESS,
} from 'actions/devices';

import deindex from 'utils/deindex';
import sortInline from 'utils/sort';

export default {
  [GET_DEVICES_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    const { centers, devices } = entities;

    return state
      .setIn(['devices', 'byId'], fromJS(devices || {}))
      .mergeDeepIn(['centers'], fromJS(centers || {}));
  },
  [GET_DEVICE_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    const { centers, devices } = entities;

    return state
      .mergeIn(['devices', 'byId'], fromJS(devices || {}))
      .mergeDeepIn(['centers'], fromJS(centers || {}));
  },
  [ADD_DEVICE_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    const { centers, devices } = entities;

    return state
      .mergeIn(['devices', 'byId'], fromJS(devices))
      .mergeDeepIn(['centers'], fromJS(centers));
  },
  [UPDATE_DEVICE_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    const { centers, devices } = entities;

    return state
      .mergeIn(['devices', 'byId'], fromJS(devices))
      .mergeDeepIn(['centers'], fromJS(centers));
  },
  [DELETE_DEVICE_SUCCESS]: (state: any, action: any): any => {
    const { id } = action.payload;

    return state.deleteIn(['devices', 'byId', id]);
  },
  [GET_DEVICE_MODELS_SUCCESS]: (state: any, action: any): any => {
    return state.setIn(['deviceModels'], fromJS(action.payload.response || []));
  },
};

const normalize = (centers: any) => (device) => {
  const center = centers[device.center];

  return {
    ...device,
    center,
  };
};

export const devicesSelector = (state: any): ?Array<DeviceT> => {
  const {
    devices: { byId },
    centers,
  } = state.entities.toJS();

  if (!byId) return undefined;

  return deindex(byId)
    .map(normalize(centers))
    .sort(sortInline('name', 'ascending'));
};

export const deviceSelector = (state: any, deviceId: ?string): ?DeviceT => {
  if (!deviceId) return undefined;

  const {
    devices: { byId },
    centers,
  } = state.entities.toJS();

  if (!centers) return undefined;
  if (!byId) return undefined;
  if (!byId[deviceId]) return undefined;

  return normalize(centers)(byId[deviceId]);
};

export const deviceModelOptionsSelector = (state: any): Array<OptionT> => {
  const { deviceModels } = state.entities.toJS();

  return deviceModels?.map((m) => ({ label: m.name, value: m.id })) || [];
};
