// @flow

import { fromJS } from 'immutable';

import { GET_DEVICE_CONFIGURATIONS_SUCCESS } from 'actions/devices';

import sortInline from 'utils/sort';

const DEVICE_CONFIGURATIONS_BY_ID = ['deviceConfigurations', 'byId'];
const DEVICE_CONFIGURATIONS_BY_DEVICE_ID = [
  'deviceConfigurations',
  'byDeviceId',
];

export default {
  [GET_DEVICE_CONFIGURATIONS_SUCCESS]: (state: any, action: any): any => {
    const {
      deviceId,
      response: {
        entities: { deviceConfigurations },
        result: { data },
      },
    } = action.payload;

    return state
      .mergeDeepIn(
        DEVICE_CONFIGURATIONS_BY_ID,
        fromJS(deviceConfigurations || {})
      )
      .setIn(
        [...DEVICE_CONFIGURATIONS_BY_DEVICE_ID, deviceId],
        fromJS(data || [])
      );
  },
};

export const deviceConfigurationsSelector = (
  state: any,
  deviceId: ?string
): ?Array<DeviceConfigurationT> => {
  if (!deviceId) return undefined;

  const {
    deviceConfigurations: { byId, byDeviceId },
  } = state.entities.toJS();

  const isFetching = !!(
    state.network.GET_DEVICE_CONFIGURATIONS ||
    state.network.GET_DEVICE_CONFIGURATIONS === undefined
  );

  if (isFetching || !byDeviceId[deviceId]) return undefined;

  const deviceConfigurationsId = byDeviceId[deviceId];

  if (!deviceConfigurationsId) return undefined;

  return deviceConfigurationsId
    .map((id) => byId[id])
    .sort(sortInline('timestamp', 'descending'));
};
