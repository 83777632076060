// @flow

type Property = string | ((obj: any) => any);
type Order = 'ascending' | 'descending';

// raw value to comparable value
const preprocessors = {
  array: (val) => val.length,
  string: (val) => val.toLowerCase(),
  object: (val) => val,
};

const getDataType = (data: any): string => {
  if (Array.isArray(data)) return 'array';
  if (data instanceof Date) return 'date';
  return typeof data;
};

// https://www.npmjs.com/package/dash-get
const get = (obj, path, fallback) => {
  if (!obj || !path) return fallback;
  const paths = Array.isArray(path) ? path : path.split('.');
  let results = obj;
  let i = 0;

  while (i < paths.length && results !== undefined && results !== null) {
    results = results[paths[i]];
    i += 1;
  }

  if (i === paths.length) {
    return results !== undefined ? results : fallback;
  }

  return results !== undefined && results !== null ? results : fallback;
};

const getValue = (obj: any, property: Property) =>
  typeof property === 'function'
    ? property(obj)
    : get(obj, property, undefined);

const getCompareValue = (value: any) => {
  const type = getDataType(value);
  const preprocessor = preprocessors[type];

  return preprocessor?.(value) || value;
};

const genericSort =
  (property: Property, order: Order) => (objA: any, objB: any) => {
    const valueA = getValue(objA, property);
    const valueB = getValue(objB, property);

    const compareValA = getCompareValue(valueA);
    const compareValB = getCompareValue(valueB);

    if (compareValA < compareValB) return order === 'ascending' ? -1 : 1;
    if (compareValA > compareValB) return order === 'ascending' ? 1 : -1;
    return 0;
  };

const combineSortFunctions =
  (...sortFns: Array<(a: any, b: any) => number>) =>
  (a: any, b: any) => {
    for (let i = 0; i < sortFns.length; i += 1) {
      const sortFnResult = sortFns[i](a, b);
      if (sortFnResult !== 0) return sortFnResult;
    }
    return 0;
  };

const sortInline = (
  properties?: Array<Property> | Property = 'id',
  orders?: Array<Order> | Order = 'ascending'
) => {
  const propertiesArray = Array.isArray(properties) ? properties : [properties];

  const sortFns = propertiesArray.map((property, index) =>
    genericSort(
      property,
      (Array.isArray(orders) ? orders[index] : orders) || 'ascending'
    )
  );

  return combineSortFunctions(...sortFns);
};

export default sortInline;
