// @flow

import * as React from 'react';

import Avatar from 'components/Avatar';

const displayName = (user: UserT) =>
  [user.title, user.firstName, user.lastName].filter(Boolean).join(' ');

const UserInfo = ({
  user,
  showAvatar = true,
}: {
  user: UserT,
  showAvatar?: boolean,
}) => {
  return (
    <>
      {showAvatar ? (
        <Avatar
          url={user.avatarThumbUrl}
          className="is-small with-margin-small"
        />
      ) : null}
      {displayName(user)}
    </>
  );
};

export default UserInfo;
