// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import type { IntlShape } from 'react-intl';

import { resetError as resetErrorAction } from 'actions/error';
import Modal from 'components/Modal';
import md5 from 'blueimp-md5';

const t = defineMessages({
  errorTitle: {
    id: 'error_modal_title',
    defaultMessage: 'An error has occurred',
  },
  refreshMessage: {
    id: 'error_modal_refresh',
    defaultMessage: 'Please refresh the app',
  },
  unknownError: {
    id: 'error_modal_unknown_error',
    defaultMessage: 'An unknown error',
  },
  forbiddenTitle: {
    id: 'error_modal_forbidden_title',
    defaultMessage: 'Insufficient rights',
  },
  forbiddenDetail: {
    id: 'error_modal_forbidden_detail',
    defaultMessage: 'You do not have the required rights to use this feature!',
  },
  notFoundTitle: {
    id: 'error_modal_not_found_title',
    defaultMessage: 'Not Found',
  },
  notFoundDetail: {
    id: 'error_modal_not_found_detail',
    defaultMessage: 'The requested resource was not found!',
  },
});

type Props = {
  forbidden: boolean,
  allUnhandled: boolean,
  notFound: boolean,
  errors: ErrorsT,
  resetError: () => void,
  intl: IntlShape,
};

const formatError = ({ title, detail }) => (
  <p key={md5(`${title}_${detail}`)}>
    <strong>{title}</strong>
    <br />
    {detail}
  </p>
);

class ErrorModal extends React.Component<Props> {
  renderError = (error, index) => {
    const {
      intl: { formatMessage },
      notFound,
      forbidden,
    } = this.props;

    if (error.title && error.detail && error.title !== error.detail) {
      return formatError(error);
    }

    if (forbidden) {
      return formatError({
        title: formatMessage(t.forbiddenTitle),
        detail: formatMessage(t.forbiddenDetail),
      });
    }

    if (notFound) {
      return formatError({
        title: formatMessage(t.notFoundTitle),
        detail: formatMessage(t.notFoundDetail),
      });
    }

    return (
      <p key={md5(`error_${index}`)}>
        {error.title || error.detail || formatMessage(t.unknownError)}
      </p>
    );
  };

  render() {
    const {
      forbidden,
      notFound,
      allUnhandled,
      resetError,
      errors,
      intl: { formatMessage },
    } = this.props;
    return (
      <Modal
        show={forbidden || notFound || allUnhandled}
        showCancel={false}
        showSubmit={false}
        cancel={resetError}
        title={formatMessage(t.errorTitle)}
      >
        {!(forbidden || notFound) ? (
          <>
            <a
              href={window.location.origin}
              title={formatMessage(t.refreshMessage)}
            >
              <h5>
                <FormattedMessage {...t.refreshMessage} />
              </h5>
            </a>
            <hr style={{ margin: '15px 0' }} />
          </>
        ) : null}

        {errors.length ? errors.map(this.renderError) : null}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors } = state;

  const forbidden = errors.length
    ? errors.some((err) => err.handlerType === 'forbidden')
    : false;

  const notFound = errors.length
    ? errors.some((err) => err.handlerType === 'not_found')
    : false;

  const allUnhandled = errors.length
    ? errors.every((err) => err.handlerType === 'unhandled')
    : false;

  return {
    forbidden,
    allUnhandled,
    notFound,
    errors,
  };
};

const mapDispatchToProps = {
  resetError: resetErrorAction,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ErrorModal));
