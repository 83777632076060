// @flow

import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { requestPasswordReset, requestAccountUnlock } from 'actions/users';

import ErrorFlash from 'components/containers/ErrorFlash';
import Button from 'components/Button';
import ResetRequestForm from './RecoverAccountForm';

import t from './translations';
import Layout from './Layout';
import useActionReset from './useActionReset';

const isAccountLockable =
  parseInt(process.env.REACT_APP_ACCOUNT_LOCKABLE, 10) === 1;

export default () => {
  const dispatch = useDispatch();
  const actionReset = useActionReset();
  const { formatMessage } = useIntl();

  const [optionChosen, setOptionChosen] = useState(!isAccountLockable);
  const [recoverType, setRecoverType] = useState(
    isAccountLockable ? 'none' : 'password'
  );
  const success = useSelector((state) => state.success);

  const isSuccess =
    success.type === 'REQUEST_PASSWORD_RESET' ||
    success.type === 'REQUEST_ACCOUNT_UNLOCK';

  useEffect(() => {
    return actionReset;
  }, [actionReset]);

  if (!optionChosen) {
    return (
      <Layout>
        <h2 className="signin-heading">
          <FormattedMessage {...t.recoverAccount} />
        </h2>

        <Button
          primary
          onClick={() => {
            setOptionChosen(true);
            setRecoverType('password');
          }}
        >
          <FormattedMessage {...t.forgotPassword} />
        </Button>

        <Button
          primary
          onClick={() => {
            setOptionChosen(true);
            setRecoverType('unlock');
          }}
        >
          <FormattedMessage {...t.requestAccountUnlock} />
        </Button>

        <Link style={{ marginTop: '10px' }} to="/login">
          {formatMessage(t.returnLogin)}
        </Link>
      </Layout>
    );
  }

  return (
    <Layout>
      <h2 className="signin-heading">
        {recoverType === 'password' ? (
          <FormattedMessage {...t.forgotPassword} />
        ) : (
          <FormattedMessage {...t.requestAccountUnlock} />
        )}
      </h2>

      <ErrorFlash
        close={false}
        substituteMessage={formatMessage(t.errorMessageFallback)}
      />

      {isSuccess ? (
        <p>{formatMessage(t.recoverSuccessMessage)}</p>
      ) : (
        <ResetRequestForm
          onSubmit={(values) =>
            recoverType === 'password'
              ? dispatch(requestPasswordReset(values))
              : dispatch(requestAccountUnlock(values))
          }
        />
      )}

      <Link style={{ marginTop: '10px' }} to="/login">
        {formatMessage(t.returnLogin)}
      </Link>
    </Layout>
  );
};
