// @flow

import * as React from 'react';
import classNames from 'classnames';

type Props = {
  children?: React.Node,
  rounded?: boolean,
  primary?: boolean,
  danger?: boolean,
  link?: boolean,
  gray?: boolean,
  filter?: boolean,
  link?: boolean,
  action?: boolean,
  amount?: number,
  isActive?: boolean,
  showZero?: boolean,
  disabled?: boolean,
  className?: string,
  style?: Object,
  onClick: (event: MouseEvent) => void,
};

const Button = ({
  className,
  rounded = false,
  primary = false,
  danger = false,
  gray = false,
  filter = false,
  link = false,
  action = false,
  amount,
  showZero = false,
  isActive,
  style,
  children,
  disabled = false,
  onClick,
}: Props) => {
  const classNameString = classNames('button', className, {
    'button-rounded': rounded,
    'button-primary': primary,
    'button-danger': danger,
    'button-gray': gray,
    'button-dynamic-filter': filter,
    'button-link': link,
    'button-action': action,
    'has-amount': amount !== undefined && (amount > 0 || showZero),
    'is-active': isActive,
  });

  return (
    <button
      type="button"
      className={classNameString}
      style={style}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
      {amount !== undefined && (amount > 0 || showZero) ? (
        <strong>{amount}</strong>
      ) : null}
    </button>
  );
};

Button.displayName = 'Button';

export default Button;
