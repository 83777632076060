// @flow

export const GET_SHARED_TENANTS_REQUEST = 'GET_SHARED_TENANTS_REQUEST';
export const GET_SHARED_TENANTS_SUCCESS = 'GET_SHARED_TENANTS_SUCCESS';
export const GET_SHARED_TENANTS_FAILURE = 'GET_SHARED_TENANTS_FAILURE';

export const getSharedTenants = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_SHARED_TENANTS_REQUEST,
      GET_SHARED_TENANTS_SUCCESS,
      GET_SHARED_TENANTS_FAILURE,
    ],
    url: 'tenant_acl',
    httpMethod: 'GET',
  },
});

export const UPDATE_SHARED_TENANTS_REQUEST = 'UPDATE_SHARED_TENANTS_REQUEST';
export const UPDATE_SHARED_TENANTS_SUCCESS = 'UPDATE_SHARED_TENANTS_SUCCESS';
export const UPDATE_SHARED_TENANTS_FAILURE = 'UPDATE_SHARED_TENANTS_FAILURE';

export const updateSharedTenants = (share: boolean): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_SHARED_TENANTS_REQUEST,
      UPDATE_SHARED_TENANTS_SUCCESS,
      UPDATE_SHARED_TENANTS_FAILURE,
    ],
    url: `tenant_acl`,
    httpMethod: 'PUT',
    payload: {
      share,
    },
    actionPayload: { share },
  },
});
