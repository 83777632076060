// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';

import { getConfigurations } from 'actions/configurations';

const Configuration = ({ children }: { children: React.Node }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(getConfigurations());
  }, [dispatch]);

  return children;
};

export default Configuration;
