import createReducer from 'reducers/createReducer';
import getType from 'utils/getType';

const initialState = {};

const updatePagination = (type) => (state, action) => {
  const {
    payload: { request, response },
  } = action;

  if (!request || !response) return state;
  if (request.page === undefined) return state;
  if (request.query && request.query.length > 0) return state;

  if (response.result && response.result.totalResults !== undefined) {
    return {
      ...state,
      [getType(type)]: {
        currentPage: request.page,
        pageSize: request.pageSize,
        totalCount: response.result.totalResults,
        totalPages: Math.ceil(response.result.totalResults / request.pageSize),
        result: response.data,
      },
    };
  }

  if (response.meta && response.meta.totalCount !== undefined)
    return {
      ...state,
      [getType(type)]: {
        currentPage: request.page,
        pageSize: response.meta.pageSize,
        totalCount: response.meta.totalCount,
        totalPages: response.meta.totalPages,
        result: response.data,
      },
    };

  if (response.result.meta && response.result.meta.totalCount !== undefined)
    return {
      ...state,
      [getType(type)]: {
        currentPage: request.page,
        pageSize: response.result.meta.pageSize,
        totalCount: response.result.meta.totalCount,
        totalPages: response.result.meta.totalPages,
        result: response.result.data,
      },
    };

  return state;
};

const detector = (type) => {
  if (type && type.endsWith('SUCCESS')) return updatePagination(type);
  return (state) => state;
};

export default createReducer(detector, initialState);
