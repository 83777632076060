// @flow

import * as React from 'react';
import { useDispatch } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { logoutAction } from '../actions/auth';

const NavbarSignOut = () => {
  const dispatch = useDispatch();

  return (
    <button
      type="button"
      className="button-link navbar-link"
      onClick={() => dispatch(logoutAction())}
    >
      <FormattedMessage id="navbar_signOut" defaultMessage="Sign out" />
    </button>
  );
};

export default NavbarSignOut;
