// @flow

export const serializeTime = (val: any): typeof undefined | Date => {
  const date = new Date(val);

  if (val && date.toString() !== 'Invalid Date') {
    return date;
  }

  return undefined;
};

export const serializeDate = (val: any): typeof undefined | Date => {
  const localDate = new Date(val);

  if (val === null || localDate.toString() === 'Invalid Date') {
    return undefined;
  }

  const date = new Date(0);

  date.setFullYear(localDate.getUTCFullYear());
  date.setMonth(localDate.getUTCMonth());
  date.setDate(localDate.getUTCDate());
  date.setHours(12, 0, 0, 0);

  return date;
};

export const serializeBoolean = (val: any): typeof undefined | boolean => {
  if (typeof val === 'boolean') return val;

  if (val === 'true') return true;
  if (val === 'false') return false;

  return undefined;
};

export const serializeString = (val: any): typeof undefined | string => {
  if (val) return val.toString();

  return undefined;
};

export const serializeFloat = (val: any): typeof undefined | number => {
  if (typeof val === 'number') return val;

  const parsed = parseFloat(val);
  if (!Number.isNaN(parsed)) return parsed;

  return undefined;
};

export default (serializersByProperty: { [string]: (any) => any }) =>
  (value: { [string]: any }) =>
    Object.keys(value).reduce(
      (acc, key) => ({
        ...acc,
        [key]: serializersByProperty[key]
          ? serializersByProperty[key](value[key])
          : value[key],
      }),
      value
    );
