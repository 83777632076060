// @flow

import { fromJS } from 'immutable';
import {
  GET_DEVICE_ATTACHMENTS_SUCCESS,
  ADD_DEVICE_ATTACHMENT_SUCCESS,
  DELETE_DEVICE_ATTACHMENT_SUCCESS,
} from 'actions/devices/attachments';
import sortInline from 'utils/sort';

const BY_ID = ['deviceAttachments', 'byId'];
const BY_DEVICE_ID = ['deviceAttachments', 'byDeviceId'];
const RESULT_PLURIAL = 'deviceAttachments';
const RESULT_SINGLE = 'deviceAttachment';

export default {
  [GET_DEVICE_ATTACHMENTS_SUCCESS]: (state: any, action: any) => {
    const {
      deviceId,
      response: { entities, result },
    } = action.payload;

    return state
      .mergeIn(BY_ID, fromJS(entities.deviceAttachments || {}))
      .setIn([...BY_DEVICE_ID, deviceId], fromJS(result[RESULT_PLURIAL]))
      .mergeDeepIn(['users', 'byId'], fromJS(entities.users || {}));
  },
  [ADD_DEVICE_ATTACHMENT_SUCCESS]: (state: any, action: any) => {
    const {
      deviceId,
      response: { entities, result },
    } = action.payload;
    const byDeviceId = state
      .getIn([...BY_DEVICE_ID, deviceId])
      .push(result[RESULT_SINGLE]);

    return state
      .mergeIn(BY_ID, fromJS(entities.deviceAttachments))
      .setIn([...BY_DEVICE_ID, deviceId], byDeviceId)
      .mergeDeepIn(['users', 'byId'], fromJS(entities.users || {}));
  },
  [DELETE_DEVICE_ATTACHMENT_SUCCESS]: (state: any, action: any) => {
    const { id, deviceId } = action.payload;
    const byDeviceIdPath = [...BY_DEVICE_ID, deviceId];
    const byDeviceId = state
      .getIn(byDeviceIdPath)
      .filter((attachmentId) => attachmentId !== id);

    return state.removeIn([...BY_ID, id]).setIn(byDeviceIdPath, byDeviceId);
  },
};

const normalize = (users: any) => (attachment) => {
  const uploadedBy = users[attachment.uploadedBy];

  return {
    ...attachment,
    uploadedBy,
  };
};

export const deviceAttachmentsSelector = (state: any, deviceId: string) => {
  const {
    deviceAttachments: { byId, byDeviceId },
    users: { byId: usersById },
  } = state.entities.toJS();
  const attachments = byDeviceId[deviceId];

  if (attachments === undefined) return attachments;

  return attachments
    .map((id) => byId[id])
    .map(normalize(usersById))
    .sort(sortInline('createdAt', 'descending'));
};
