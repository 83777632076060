// @flow

import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useRouteMatch } from 'react-router-dom';

import Main from 'components/Main';
import Loading from 'components/Loading';
import FeatureFlag from 'components/FeatureFlag';
import { IT_ADMIN, TENANT_ADMIN, VIEW_USERS } from 'rights';

const IamSettings = lazy(() => import('./IamSettings'));
const InfrastructureSettings = lazy(() => import('./InfrastructureSettings'));
const DeviceSettings = lazy(() => import('./DeviceSettings'));
const SystemSettings = lazy(() => import('./SystemSettings'));
const Profile = lazy(() => import('../Profile'));

const Shell = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <Main>
          <Loading />
        </Main>
      }
    >
      <Switch>
        <Route exact path={path}>
          <Redirect to={`${path}/infrastructure`} />

          <FeatureFlag rights={[IT_ADMIN, TENANT_ADMIN, VIEW_USERS]}>
            <Redirect to={`${path}/iam`} />
          </FeatureFlag>
        </Route>

        <Route path={`${path}/iam`}>
          <IamSettings />
        </Route>

        <Route path={`${path}/infrastructure`}>
          <InfrastructureSettings />
        </Route>

        <Route path={`${path}/devices`}>
          <DeviceSettings />
        </Route>

        <Route path={`${path}/system`}>
          <SystemSettings />
        </Route>

        <Route path={`${path}/profile`}>
          <Profile />
        </Route>
      </Switch>
    </Suspense>
  );
};

export default Shell;
