// @flow

import * as React from 'react';

// $FlowIgnore
import { ReactComponent as ConnectLogo } from 'assets/images/dwa-connect-logo.svg';

// $FlowIgnore
import { ReactComponent as DwaLogo } from 'assets/images/dwa-pollet-medical-group-logo.svg';

const style = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

function Layout({ children }: { children: React.Node }) {
  return (
    <div className="signin" style={style}>
      <DwaLogo style={{ height: '50px' }} />
      <ConnectLogo style={{ height: '50px', margin: '15px 0' }} />
      {children}
    </div>
  );
}

export default Layout;
