// @flow

import * as React from 'react';
import { useState } from 'react';
import { Switch, Route } from 'react-router-dom';

import { version } from '../../package.json';
import { isIE } from 'utils/browserChecks';

import UnsupportedBrowserModal from './UnsupportedBrowserModal';
import PerspectiveNavigation from './PerspectiveNavigation';

import WTSNavigation from './wts/Navigation';
import AdminNavigation from './admin/Navigation';
import Popover from 'react-popover';

const Sidebar = () => {
  const [browserModalVisibility, setBrowserModalVisibility] = useState(false);

  const toggleBrowserSupportModal = () =>
    setBrowserModalVisibility((visibility) => !visibility);

  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <>
      <aside className="sidebar">
        <ul className="sidebar-links">
          {isIE ? (
            <li
              className="warning"
              role="presentation"
              onClick={toggleBrowserSupportModal}
            >
              <i className="material-icons">public</i>
            </li>
          ) : null}

          <Switch>
            <Route path="/wts" component={WTSNavigation} />
            <Route path="/admin" component={AdminNavigation} />
          </Switch>

          <span className="sidebar-spacer" />

          <PerspectiveNavigation />

          <Popover
            isOpen={isOpen}
            preferPlace="row"
            body={<span>v{version}</span>}
            style={{ maxWidth: '110px' }}
          >
            <li
              onMouseEnter={() => setIsOpen(true)}
              onMouseLeave={() => setIsOpen(false)}
            >
              <div>
                <i className="material-icons">info_outline</i>
              </div>
            </li>
          </Popover>
        </ul>
      </aside>

      <UnsupportedBrowserModal
        show={browserModalVisibility}
        cancel={toggleBrowserSupportModal}
      />
    </>
  );
};

export default Sidebar;
