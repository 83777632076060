import createReducer from 'reducers/createReducer';

import { LOCATION_CHANGE } from 'actions/router';

import {
  SET_FORM,
  REGISTER_FORM_VALUE,
  RESET_FORM,
  SET_FORM_BY_FORM_NAME,
  REGISTER_FORM_VALUE_BY_FORM_NAME,
  RESET_FORM_BY_NAME,
} from 'actions/form';

export const initialState = {
  form: undefined,
  values: {},
  byName: {},
};

const mapping = {
  [SET_FORM]: (state, action) => {
    const {
      payload: { config },
    } = action;

    return { ...state, form: config };
  },

  [REGISTER_FORM_VALUE]: (state, action) => {
    const {
      payload: { value },
    } = action;

    const values = { ...state.values, ...value };

    return { ...state, values };
  },

  [RESET_FORM]: () => initialState,

  [SET_FORM_BY_FORM_NAME]: (state, action) => {
    const {
      payload: { config, formName },
    } = action;

    const byName = {
      ...state.byName,
      [formName]: {
        form: config,
        values: {},
      },
    };

    return { ...state, byName };
  },

  [REGISTER_FORM_VALUE_BY_FORM_NAME]: (state, action) => {
    const {
      payload: { value, formName },
    } = action;

    const values = { ...state.byName[formName].values, ...value };
    const form = { ...state.byName[formName], values };
    const byName = { ...state.byName, [formName]: form };

    return { ...state, byName };
  },

  [RESET_FORM_BY_NAME]: (state, action) => {
    const {
      payload: { formName },
    } = action;

    const { [formName]: value, ...byName } = state.byName;

    return { ...state, byName };
  },

  [LOCATION_CHANGE]: (state, { payload }) => {
    if (payload.action === 'REPLACE') return state;

    return initialState;
  },
};

export const formSelector = (state, withName) => {
  const { form, byName } = state.form;

  if (!withName) return form;
  if (!(withName in byName)) return undefined;

  return byName[withName].form;
};

export const valuesSelector = (state, withName) => {
  const { values, byName } = state.form;

  if (!withName) return values;
  if (!(withName in byName)) return undefined;

  return byName[withName].values;
};

export default createReducer(mapping, initialState);
