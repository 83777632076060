// @flow
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { resetSuccess } from 'actions/success';
import { resetError } from 'actions/error';

export default () => {
  const dispatch = useDispatch();

  const actionReset = useCallback(() => {
    dispatch(resetSuccess());
    dispatch(resetError());
  }, [dispatch]);

  return actionReset;
};
