export default (mapping, initialState) =>
  (state = initialState, action) => {
    const { type } = action;

    if (!type) return state;

    const getReduceMethod = (map) => {
      // Literal actiontype to reducer method mapping
      if (typeof map === 'object') return map[type];

      // Detector method that returns a reducer method based on some logic
      // General rules:
      // - Do specific type matching first
      // - Do generic/partial type matching last
      //   => this way specific types won't be handled by the generic reducer
      if (typeof map === 'function') return map(type);

      return undefined;
    };

    const reduceMethod = getReduceMethod(mapping);
    return reduceMethod ? reduceMethod(state, action) : state;
  };
