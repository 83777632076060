// @flow

import { fromJS } from 'immutable';

import {
  GET_DEVICE_ALERTS_SUCCESS,
  RESOLVE_DEVICE_ALERTS_SUCCESS,
} from 'actions/devices';

import sortInline from 'utils/sort';

const DEVICE_ALERTS_BY_ID = ['deviceAlerts', 'byId'];
const DEVICE_ALERTS_BY_DEVICE_ID = ['deviceAlerts', 'byDeviceId'];

export default {
  [GET_DEVICE_ALERTS_SUCCESS]: (state: any, action: any): any => {
    const {
      deviceId,
      response: {
        entities: { alerts },
        result: { data: ids },
      },
    } = action.payload;

    return state
      .mergeDeepIn(DEVICE_ALERTS_BY_ID, fromJS(alerts || {}))
      .setIn([...DEVICE_ALERTS_BY_DEVICE_ID, deviceId], fromJS(ids || []));
  },
  [RESOLVE_DEVICE_ALERTS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { alerts },
      },
    } = action.payload;

    return state.mergeDeepIn(DEVICE_ALERTS_BY_ID, fromJS(alerts || {}));
  },
};

export const deviceAlertsSelector = (
  state: any,
  deviceId: string
): ?Array<DeviceLogT> => {
  const {
    deviceAlerts: { byId, byDeviceId },
  } = state.entities.toJS();

  const deviceAlertIds = byDeviceId[deviceId];

  if (deviceAlertIds === undefined) return undefined;

  return deviceAlertIds
    .map((id) => byId[id])
    .sort(sortInline('createdAt', 'descending'));
};
