// @flow

import * as React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

import { currentUser as currentUserSelector } from 'reducers/auth';
import { featureCheck } from 'components/FeatureFlag';

type Props = {
  children: React.Node,
  rights: Array<string>,
  test?: (UserT) => boolean,
  exact?: boolean,
  path?: string,
  redirectPath?: string,
};

const AuthorizedRoute = ({
  children,
  rights,
  test,
  path,
  redirectPath,
}: Props) => {
  const currentUser: UserT = useSelector((state) => currentUserSelector(state));

  const authorized = featureCheck({ currentUser, rights });
  const predicate = typeof test === 'function' ? test(currentUser) : true;

  return (
    <Route path={path}>
      {authorized && predicate ? (
        children
      ) : (
        <Redirect to={redirectPath || '/'} />
      )}
    </Route>
  );
};

export default AuthorizedRoute;
