// @flow

import { schema } from 'normalizr';
import type { Dispatch } from 'redux';

import createSerializer, { serializeTime } from 'utils/schemaSerializers';

import { getCurrentUser } from 'actions/users';

export const rightSchema = new schema.Entity('rights');

export const roleSchema = new schema.Entity(
  'roles',
  { rights: [rightSchema] },
  {
    processStrategy: createSerializer({
      updatedAt: serializeTime,
      createdAt: serializeTime,
    }),
  }
);

export const GET_RIGHTS_REQUEST = 'GET_RIGHTS_REQUEST';
export const GET_RIGHTS_SUCCESS = 'GET_RIGHTS_SUCCESS';
export const GET_RIGHTS_FAILURE = 'GET_RIGHTS_FAILURE';

export const getRights = (): NetworkActionT => ({
  CALL_API: {
    types: [GET_RIGHTS_REQUEST, GET_RIGHTS_SUCCESS, GET_RIGHTS_FAILURE],
    url: 'rights',
    httpMethod: 'GET',
    normalizeSchema: [rightSchema],
  },
});

export const UPDATE_RIGHTS_FOR_ROLE = 'UPDATE_RIGHTS_FOR_ROLE';
export const UPDATE_RIGHTS_FOR_ROLE_REQUEST = 'UPDATE_RIGHTS_FOR_ROLE_REQUEST';
export const UPDATE_RIGHTS_FOR_ROLE_SUCCESS = 'UPDATE_RIGHTS_FOR_ROLE_SUCCESS';
export const UPDATE_RIGHTS_FOR_ROLE_FAILURE = 'UPDATE_RIGHTS_FOR_ROLE_FAILURE';

export type UpdateRightsForRoleArgsT = {
  id: string,
  rights: Array<number>,
};

export const updateRightsForRole = ({
  id,
  rights,
}: UpdateRightsForRoleArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_RIGHTS_FOR_ROLE_REQUEST,
      UPDATE_RIGHTS_FOR_ROLE_SUCCESS,
      UPDATE_RIGHTS_FOR_ROLE_FAILURE,
    ],
    url: `roles/${id}/rights`,
    httpMethod: 'PUT',
    payload: { role: { rights } },
    normalizeSchema: { 'users/role': roleSchema },
  },
});

export const GET_ROLES_REQUEST = 'GET_ROLES_REQUEST';
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS';
export const GET_ROLES_FAILURE = 'GET_ROLES_FAILURE';

export const getRoles = (): NetworkActionT => ({
  CALL_API: {
    types: [GET_ROLES_REQUEST, GET_ROLES_SUCCESS, GET_ROLES_FAILURE],
    url: 'roles',
    httpMethod: 'GET',
    normalizeSchema: { 'users/roles': [roleSchema] },
  },
});

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAILURE = 'CREATE_ROLE_FAILURE';

export type CreateRoleArgsT = {
  name: string,
};

export const createRole = ({ name }: CreateRoleArgsT): NetworkActionT => ({
  CALL_API: {
    types: [CREATE_ROLE_REQUEST, CREATE_ROLE_SUCCESS, CREATE_ROLE_FAILURE],
    url: 'roles',
    httpMethod: 'POST',
    payload: { role: { name } },
    normalizeSchema: { 'users/role': roleSchema },
  },
});

export const UPDATE_ROLE_REQUEST = 'UPDATE_ROLE_REQUEST';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAILURE = 'UPDATE_ROLE_FAILURE';

export type UpdateRoleArgsT = {
  id: string,
  name: string,
};

export const updateRole = ({ id, name }: UpdateRoleArgsT): NetworkActionT => ({
  CALL_API: {
    types: [UPDATE_ROLE_REQUEST, UPDATE_ROLE_SUCCESS, UPDATE_ROLE_FAILURE],
    url: `roles/${id}`,
    httpMethod: 'PUT',
    payload: { role: { name } },
    normalizeSchema: { 'users/role': roleSchema },
  },
});

export const updateRoleAndRefreshCurrentUser =
  (args: UpdateRoleArgsT) => (dispatch: Dispatch<Object>) =>
    dispatch(updateRole(args)).then(() => dispatch(getCurrentUser()));

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';

export type DeleteRoleArgsT = {
  id: string,
};

export const deleteRole = ({ id }: DeleteRoleArgsT): NetworkActionT => ({
  CALL_API: {
    types: [DELETE_ROLE_REQUEST, DELETE_ROLE_SUCCESS, DELETE_ROLE_FAILURE],
    url: `roles/${id}`,
    httpMethod: 'DELETE',
    actionPayload: { id },
  },
});
