// @flow

import { centerSchema } from './index';

export const UPDATE_CENTER_EMAIL_CONTACT_REQUEST =
  'UPDATE_CENTER_EMAIL_CONTACT_REQUEST';
export const UPDATE_CENTER_EMAIL_CONTACT_SUCCESS =
  'UPDATE_CENTER_EMAIL_CONTACT_SUCCESS';
export const UPDATE_CENTER_EMAIL_CONTACT_FAILURE =
  'UPDATE_CENTER_EMAIL_CONTACT_FAILURE';

export type UpdateCenterEmailContactArgsT = {
  id: string,
  emailContact: ?string,
};

export const updateCenterEmailContact = ({
  id,
  emailContact,
}: UpdateCenterEmailContactArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CENTER_EMAIL_CONTACT_REQUEST,
      UPDATE_CENTER_EMAIL_CONTACT_SUCCESS,
      UPDATE_CENTER_EMAIL_CONTACT_FAILURE,
    ],
    url: `centers/${id}/email_contact`,
    httpMethod: 'PUT',
    payload: { center: { emailContact } },
    normalizeSchema: { center: centerSchema },
  },
});
