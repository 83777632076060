// @flow

import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Pages from './Pages';

const t = defineMessages({
  headline: {
    id: 'forbidden_headline',
    defaultMessage: 'Woops, looks like you are not allowed to view this page.',
  },
  backHome: { id: 'forbidden_back_home', defaultMessage: 'Bring me back home' },
  backHomeTitle: {
    id: 'forbidden_back_home_title',
    defaultMessage: 'Back to dashboard',
  },
});

const Forbidden = () => {
  const { formatMessage } = useIntl();

  return (
    <Pages>
      <div className="page-container">
        <h2 className="page-title is-beating">
          <i className="material-icons">security</i>
          <br />
          <FormattedMessage {...t.headline} />
        </h2>

        <br />

        <NavLink
          to="/"
          title={formatMessage(t.backHomeTitle)}
          className="button button-success"
        >
          <span>
            <FormattedMessage {...t.backHome} />
          </span>
        </NavLink>
      </div>
    </Pages>
  );
};

export default Forbidden;
