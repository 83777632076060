// @flow

import { fromJS } from 'immutable';

import {
  GET_ROLES_SUCCESS,
  CREATE_ROLE_SUCCESS,
  UPDATE_ROLE_SUCCESS,
  DELETE_ROLE_SUCCESS,
} from 'actions/permissions';

import deindex from 'utils/deindex';

const ROLES_PATH = ['roles'];

export default {
  [GET_ROLES_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    return state.setIn(ROLES_PATH, fromJS(entities.roles || {}));
  },
  [CREATE_ROLE_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    return state.mergeIn(ROLES_PATH, fromJS(entities.roles));
  },
  [UPDATE_ROLE_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    return state.mergeIn(ROLES_PATH, fromJS(entities.roles));
  },
  [DELETE_ROLE_SUCCESS]: (state: any, action: any): any => {
    const { id } = action.payload;

    return state.deleteIn([...ROLES_PATH, id]);
  },
};

export const rolesSelector = (state: any): ?Array<RoleT> => {
  const roles = state.entities.getIn(ROLES_PATH).toJS();

  if (!roles) return undefined;

  return deindex(roles);
};
