import createReducer from 'reducers/createReducer';

import { LOCATION_CHANGE } from 'actions/router';
import { START_ACTIVITY, CANCEL_ACTIVITY } from 'actions/activity';

export const initialState = {
  name: undefined,
  payload: undefined,
};

export const startActivity = (state, action) => {
  const {
    payload: { name, payload },
  } = action;

  return { ...state, name, payload };
};

export const cancelActivity = () => initialState;

const mapping = (type) => {
  if (type === START_ACTIVITY) return startActivity;
  if (type === CANCEL_ACTIVITY) return cancelActivity;
  if (type === LOCATION_CHANGE) return cancelActivity;

  if (type.startsWith('DELETE') && type.endsWith('SUCCESS'))
    return cancelActivity;

  return (state) => state;
};

export default createReducer(mapping, initialState);
