// @flow

import { resetSuccess } from 'actions/success';
import { resetError } from 'actions/error';

export const CANCEL_ACTIVITY = 'CANCEL_ACTIVITY';

export const cancelActivity = () => ({ type: CANCEL_ACTIVITY });

export const cancelActionBarActivity =
  () => (dispatch: (action: Object) => void) => {
    dispatch(cancelActivity());
    dispatch(resetSuccess());
    dispatch(resetError());
  };

export const START_ACTIVITY = 'START_ACTIVITY';

export const startActivityAction = (name: string, payload: ?Object) => ({
  type: START_ACTIVITY,
  payload: {
    name,
    payload,
  },
});

export const startActivity =
  (name: string, payload: ?Object) => (dispatch: (action: Object) => void) => {
    dispatch(cancelActivity());
    dispatch(resetSuccess());
    dispatch(resetError());
    dispatch(startActivityAction(name, payload));
  };
