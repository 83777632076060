// @flow

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import t from './translations';

type Props = {
  onSubmit: ({ email: string }) => void,
};

export default ({ onSubmit }: Props) => {
  const [email, setEmail] = useState('');
  const { formatMessage } = useIntl();

  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setEmail(value);
  };

  const handleSubmit = (event: SyntheticInputEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSubmit({ email });
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="email" className="sr-only">
        {formatMessage(t.email)}
      </label>
      <input
        autoFocus
        type="email"
        id="email"
        name="email"
        className="form-control"
        placeholder={formatMessage(t.email)}
        value={email}
        onChange={handleChange}
      />

      <button className="button button-primary button-block" type="submit">
        {formatMessage(t.requestSubmit)}
      </button>
    </form>
  );
};
