// @flow

import { decamelizeKeys } from 'humps';
import { stringify } from 'qs';

export default (queryParams: Object): string => {
  if (!queryParams) return '';

  const params = decamelizeKeys(queryParams);

  const queryString = stringify(params, {
    indices: false,
    arrayFormat: 'brackets',
  });

  return `?${queryString}`;
};
