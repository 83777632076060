// @flow

import React, { Component } from 'react';
import type { Location } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { authenticatorType } from 'authenticator';

import { loginAction, resetAuthErrorAction } from 'actions/auth';
import { authenticatedSelector } from 'reducers/auth';

import Loader from 'components/Loading';

import Layout from './Layout';
import LoginForm from './LoginForm';

import t from './translations';

const isAccountLockable =
  parseInt(process.env.REACT_APP_ACCOUNT_LOCKABLE, 10) === 1;

const isStaging = parseInt(process.env.REACT_APP_STAGING_RIBBON, 10) === 1;

type Props = {
  isFetching: boolean,
  error: ?string,
  redirectToReferrer: ?string,
  location: { ...Location, state?: any },
  login: (Object) => void,
  resetAuthError: () => void,
};

class Login extends Component<Props> {
  componentWillUnmount() {
    const { resetAuthError } = this.props;
    resetAuthError();
  }

  login = (credentials) => {
    const { login } = this.props;
    login(credentials);
  };

  render() {
    const { isFetching, error, redirectToReferrer, location } = this.props;

    const { referrer } = location.state || { referrer: { pathname: '/' } };

    if (redirectToReferrer) {
      return <Redirect to={referrer} />;
    }

    if (isFetching) {
      return (
        <div>
          <Loader />
        </div>
      );
    }

    const microsoftStrategy = (
      <div className="login-button">
        <button
          type="button"
          className="button button-primary"
          onClick={this.login}
        >
          <FormattedMessage {...t.loginMicrosoft} />
        </button>
      </div>
    );

    const credentialsStrategy = (
      <>
        <LoginForm onSubmit={this.login} />
        <Link to="/login/recover_account">
          {isAccountLockable ? (
            <FormattedMessage {...t.recoverAccount} />
          ) : (
            <FormattedMessage {...t.forgotPassword} />
          )}
        </Link>
      </>
    );

    const strategy =
      authenticatorType === 'oidc' ? microsoftStrategy : credentialsStrategy;

    return (
      <>
        {isStaging ? (
          <div className="banner banner-warning" role="alert">
            <strong>
              <FormattedMessage {...t.acceptationEnvironmentDisclaimer} />
            </strong>
          </div>
        ) : null}

        <Layout>
          <h2 className="signin-heading">
            <FormattedMessage {...t.signIn} />
          </h2>

          {error ? (
            <div className="alert alert-danger" role="alert">
              <i className="material-icons">error</i>
              {error === 'Unauthorized' ? (
                <FormattedMessage {...t.errorMessageLoginFallback} />
              ) : (
                error
              )}
            </div>
          ) : null}

          {strategy}
        </Layout>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    auth: { isFetching, error },
  } = state;

  const isAuthenticated = authenticatedSelector(state);

  return {
    isFetching,
    error,
    redirectToReferrer: isAuthenticated,
  };
};

const mapDispatchToProps = {
  login: loginAction,
  resetAuthError: resetAuthErrorAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
