// @flow

import { fromJS } from 'immutable';

import {
  GET_CONFIGURATIONS_SUCCESS,
  UPDATE_CONFIGURATION_SUCCESS,
} from 'actions/configurations';

import deindex from 'utils/deindex';

export default {
  [GET_CONFIGURATIONS_SUCCESS]: (state: any, action: any): any => {
    const { response } = action.payload;
    const { configurations } = response.entities;

    return state.mergeIn(['configurations'], fromJS(configurations));
  },
  [UPDATE_CONFIGURATION_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { configurations },
      },
    } = action.payload;

    return state.mergeDeepIn(['configurations'], fromJS(configurations));
  },
};

export const configurationSelector = (
  state: any,
  key: string
): ?ConfigurationT => {
  const configurations = state.entities.get('configurations').toJS();
  const configuration = configurations[key];

  if (!configuration) return undefined;

  return configuration;
};

export const configurationsSelector = (state: any): ?Array<ConfigurationT> => {
  const configurations = state.entities.get('configurations').toJS();

  if (!configurations) return undefined;

  return deindex(configurations);
};
