// @flow

import { defineMessages } from 'react-intl';
import type { IntlShape } from 'react-intl';
import {
  required,
  minLength,
  strongPassword,
} from 'components/Form/validators/builder';

const t = defineMessages({
  repeatPasswordError: {
    id: 'util_password_password_confirmation_error',
    defaultMessage: 'Password and password confirmation should match',
  },
});

export const strongPasswordPolicy =
  parseInt(process.env.REACT_APP_ENFORCE_PASSWORD_POLICY, 10) === 1;

export const passwordValidations = (intl: IntlShape) =>
  strongPasswordPolicy
    ? [required(intl), minLength(5, intl), strongPassword(intl)]
    : [required(intl), minLength(5, intl)];

export const validatePasswordConfirmationEquality =
  (intl: IntlShape) =>
  (errs: Object, values: Object): Object => {
    const pass = values.password ?? '';
    const repeatPass = values.passwordConfirmation ?? '';

    if (pass === repeatPass) return errs;

    const { password = [], passwordConfirmation = [] } = errs;

    return {
      ...errs,
      password: [...password, intl.formatMessage(t.repeatPasswordError)],
      passwordConfirmation: [
        ...passwordConfirmation,
        intl.formatMessage(t.repeatPasswordError),
      ],
    };
  };
