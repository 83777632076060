// @flow
import * as React from 'react';
import classnames from 'classnames';

type Props = {
  values: Array<Object>,

  long?: boolean,
  style?: Object,
};

const DataList = ({ values, long = false }: Props) => {
  const renderValue = (value, index) => {
    const classNames = classnames({
      multiline: value.multiline,
      wrap: value.wrap,
      'action-button': value.actionButton,
    });

    const dtElement = (
      <dt className={classNames} key={`label-${index}`}>
        {value.label}
      </dt>
    );

    const ddElement = (
      <dd className={classNames} key={`value-${index}`}>
        {value.value}
      </dd>
    );

    return value.multiline
      ? [dtElement, value.actionButton, ddElement].filter(Boolean)
      : [dtElement, ddElement, value.actionButton].filter(Boolean);
  };

  return (
    <dl className={classnames('description-horizontal', { 'dt-long': long })}>
      {values.map((val, index) => renderValue(val, index))}
    </dl>
  );
};

export default DataList;
