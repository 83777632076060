// @flow

export const SET_FORM = 'SET_FORM';

export const setForm = (config: Object) => ({
  type: SET_FORM,
  payload: {
    config,
  },
});

export const SET_FORM_BY_FORM_NAME = 'SET_FORM_BY_FORM_NAME';

export const setFormByFormName = (config: Object, formName: string) => ({
  type: SET_FORM_BY_FORM_NAME,
  payload: {
    config,
    formName,
  },
});

export const REGISTER_FORM_VALUE = 'REGISTER_FORM_VALUE';

export const registerFormValue = (value: Object) => ({
  type: REGISTER_FORM_VALUE,
  payload: {
    value,
  },
});

export const REGISTER_FORM_VALUE_BY_FORM_NAME =
  'REGISTER_FORM_VALUE_BY_FORM_NAME';

export const registerFormValueByFormName = (
  value: Object,
  formName: string
) => ({
  type: REGISTER_FORM_VALUE_BY_FORM_NAME,
  payload: {
    value,
    formName,
  },
});

export const RESET_FORM = 'RESET_FORM';

export const resetForm = () => ({ type: RESET_FORM });

export const RESET_FORM_BY_NAME = 'RESET_FORM_BY_NAME';

export const resetFormByName = (formName: string) => ({
  type: RESET_FORM_BY_NAME,
  payload: {
    formName,
  },
});

export const SET_CLIENT_FORM_ERRORS = 'SET_CLIENT_FORM_ERRORS';

export const setClientFormErrors = (formErrors: Object) => {
  const errors = Object.keys(formErrors).reduce(
    (acc, field) => [
      ...acc,
      ...formErrors[field].map((error) => ({
        title: error,
        detail: error,
        source: field,
      })),
    ],
    []
  );

  return {
    type: SET_CLIENT_FORM_ERRORS,
    payload: errors,
    error: true,
  };
};
