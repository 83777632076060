// @flow

import React, { lazy, Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import { VIEW_RECORD } from 'rights';

import Main from 'components/Main';
import Loading from 'components/Loading';
import NotFoundRedirect from 'views/NotFoundRedirect';
import AuthorizedRoute from 'views/AuthorizedRoute';

const Dashboard = lazy(() => import('./dashboard/container'));
const Device = lazy(() => import('./device'));
const Profile = lazy(() => import('../Profile'));

const Shell = () => {
  const { path } = useRouteMatch();

  return (
    <Suspense
      fallback={
        <Main>
          <Loading />
        </Main>
      }
    >
      <Switch>
        <AuthorizedRoute
          exact
          path={path}
          rights={[VIEW_RECORD]}
          redirectPath="wts/profile"
        >
          <Dashboard />
        </AuthorizedRoute>

        <AuthorizedRoute
          path={`${path}/devices/:deviceId`}
          rights={[VIEW_RECORD]}
          redirectPath="wts/profile"
        >
          <Device />
        </AuthorizedRoute>

        <Route path={`${path}/profile`}>
          <Profile />
        </Route>

        <NotFoundRedirect />
      </Switch>
    </Suspense>
  );
};

export default Shell;
