// @flow

import * as React from 'react';

import defaultAvatar from 'assets/images/avatar_default_original.png';

type Props = {
  url: string,
  className?: string,
  children?: React.Node,
};

const Avatar = ({ url = defaultAvatar, className, children }: Props) => {
  const style = {
    backgroundImage: `url(${url})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  };

  const classString = className ? `avatar ${className}` : 'avatar';

  return (
    <div className={classString} style={style}>
      {children}
    </div>
  );
};

Avatar.displayName = 'Avatar';

export default Avatar;
