// @flow

import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

import Pages from './Pages';

const t = defineMessages({
  headline: {
    id: 'notfound_headline',
    defaultMessage:
      'Woops, looks like the page you were looking for is not found.',
  },
  backHome: { id: 'notfound_back_home', defaultMessage: 'Bring me back home' },
  backHomeTitle: {
    id: 'notfound_back_home_title',
    defaultMessage: 'Back to dashboard',
  },
});

const NotFound = () => {
  const { formatMessage } = useIntl();

  return (
    <Pages>
      <div className="page-container">
        <h2 className="page-title is-shaking">
          <i className="material-icons">location_searching</i>
          <br />
          <FormattedMessage {...t.headline} />
        </h2>
        <br />
        <NavLink
          to="/"
          title={formatMessage(t.backHomeTitle)}
          className="button button-success"
        >
          <span>
            <FormattedMessage {...t.backHome} />
          </span>
        </NavLink>
      </div>
    </Pages>
  );
};

export default NotFound;
