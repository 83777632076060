// @flow

import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useQueryString from 'hooks/useQueryString';
import { strongPasswordPolicy } from 'utils/password';

import { resetPassword } from 'actions/users';

import ErrorFlash from 'components/containers/ErrorFlash';
import Flash from 'components/Flash';
import Section from 'components/Section';

import t from './translations';
import Layout from './Layout';
import ResetPasswordForm from './ResetPasswordForm';
import useActionReset from './useActionReset';

export default () => {
  const dispatch = useDispatch();
  const actionReset = useActionReset();
  const { formatMessage } = useIntl();

  const { token } = useQueryString();
  const success = useSelector((state) => state.success);

  useEffect(() => {
    return actionReset;
  }, [actionReset]);

  return (
    <Layout>
      <h2 className="signin-heading">
        <FormattedMessage {...t.resetPassword} />
      </h2>

      <ErrorFlash
        close={false}
        substituteMessage={formatMessage(t.errorMessageFallback)}
      />

      {success.type === 'RESET_PASSWORD' ? (
        <p>{formatMessage(t.resetSuccessMessage)}</p>
      ) : (
        [
          strongPasswordPolicy && (
            <Section key="password-info-flash">
              <Flash
                primary
                message={formatMessage(t.passwordInfo)}
                close={false}
              />
            </Section>
          ),
          <ResetPasswordForm
            key="reset-password-form"
            onSubmit={(values) => dispatch(resetPassword({ ...values, token }))}
          />,
        ].filter(Boolean)
      )}

      <Link style={{ marginTop: '10px' }} to="/login">
        {formatMessage(t.returnLogin)}
      </Link>
    </Layout>
  );
};
