// @flow

import * as React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Navbar from './Navbar';
import Sidebar from './Sidebar';

import ErrorModal from './ErrorModal';

import CurrentUserProvider from './CurrentUserProvider';
import NotFoundRedirect from './NotFoundRedirect';

import WTSShell from './wts/Shell';
import AdminShell from './admin/Shell';

const App = () => (
  <CurrentUserProvider>
    <div className="wrapper">
      <Navbar />
      <Sidebar />

      <Switch>
        <Route exact path="/">
          <Redirect to="/wts" />
        </Route>

        <Route path="/wts" component={WTSShell} />
        <Route path="/admin" component={AdminShell} />

        <Route component={NotFoundRedirect} />
      </Switch>

      <ErrorModal />
    </div>
  </CurrentUserProvider>
);

export default App;
