// @flow

import { schema } from 'normalizr';
import type { Dispatch } from 'redux';

import { passwordEnforcingAuthentication } from 'authenticator';
import createSerializer, { serializeTime } from 'utils/schemaSerializers';

export const userSchema = new schema.Entity(
  'users',
  {},
  {
    processStrategy: createSerializer({
      updatedAt: serializeTime,
      createdAt: serializeTime,
    }),
  }
);

export const GET_CURRENT_USER_REQUEST = 'GET_CURRENT_USER_REQUEST';
export const GET_CURRENT_USER_SUCCESS = 'GET_CURRENT_USER_SUCCESS';
export const GET_CURRENT_USER_FAILURE = 'GET_CURRENT_USER_FAILURE';

export const getCurrentUser = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_CURRENT_USER_REQUEST,
      GET_CURRENT_USER_SUCCESS,
      GET_CURRENT_USER_FAILURE,
    ],
    url: 'current_user',
    httpMethod: 'GET',
    normalizeSchema: { user: userSchema },
  },
});

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

const PAGE_SIZE = 30;

type GetUsersArgsT = {
  page?: ?number,
  scope?: string,
};

export const getUsers = ({
  page,
  scope = 'active',
}: GetUsersArgsT): NetworkActionT => ({
  CALL_API: {
    types: [GET_USERS_REQUEST, GET_USERS_SUCCESS, GET_USERS_FAILURE],
    url: 'users',
    httpMethod: 'GET',
    params: {
      page,
      pageSize: PAGE_SIZE,
      sortKeys: 'last_name asc',
      scope,
    },
    normalizeSchema: { data: [userSchema] },
  },
});

export const REQUEST_ACCOUNT_UNLOCK_REQUEST = 'REQUEST_ACCOUNT_UNLOCK_REQUEST';
export const REQUEST_ACCOUNT_UNLOCK_SUCCESS = 'REQUEST_ACCOUNT_UNLOCK_SUCCESS';
export const REQUEST_ACCOUNT_UNLOCK_FAILURE = 'REQUEST_ACCOUNT_UNLOCK_FAILURE';

type RequestAccountUnlockArgsT = {
  email: string,
};

export const requestAccountUnlock = ({
  email,
}: RequestAccountUnlockArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      REQUEST_ACCOUNT_UNLOCK_REQUEST,
      REQUEST_ACCOUNT_UNLOCK_SUCCESS,
      REQUEST_ACCOUNT_UNLOCK_FAILURE,
    ],
    url: 'users/unlock',
    payload: { user: { username: email } },
    httpMethod: 'POST',
  },
});

export const UNLOCK_ACCOUNT_REQUEST = 'UNLOCK_ACCOUNT_REQUEST';
export const UNLOCK_ACCOUNT_SUCCESS = 'UNLOCK_ACCOUNT_SUCCESS';
export const UNLOCK_ACCOUNT_FAILURE = 'UNLOCK_ACCOUNT_FAILURE';

type UnlockAccountArgsT = {
  token: string,
};

export const unlockAccount = ({
  token,
}: UnlockAccountArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UNLOCK_ACCOUNT_REQUEST,
      UNLOCK_ACCOUNT_SUCCESS,
      UNLOCK_ACCOUNT_FAILURE,
    ],
    url: 'users/unlock',
    payload: {
      user: { unlockToken: token },
    },
    httpMethod: 'PUT',
  },
});

export const REQUEST_PASSWORD_RESET_REQUEST = 'REQUEST_PASSWORD_RESET_REQUEST';
export const REQUEST_PASSWORD_RESET_SUCCESS = 'REQUEST_PASSWORD_RESET_SUCCESS';
export const REQUEST_PASSWORD_RESET_FAILURE = 'REQUEST_PASSWORD_RESET_FAILURE';

type RequestPasswordResetArgsT = {
  email: string,
};

export const requestPasswordReset = ({
  email,
}: RequestPasswordResetArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      REQUEST_PASSWORD_RESET_REQUEST,
      REQUEST_PASSWORD_RESET_SUCCESS,
      REQUEST_PASSWORD_RESET_FAILURE,
    ],
    url: 'users/password',
    payload: { user: { username: email } },
    httpMethod: 'POST',
  },
});

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

type ResetPasswordArgsT = {
  token: string,
  password: string,
  passwordConfirmation: string,
};

export const resetPassword = ({
  token,
  password,
  passwordConfirmation,
}: ResetPasswordArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      RESET_PASSWORD_REQUEST,
      RESET_PASSWORD_SUCCESS,
      RESET_PASSWORD_FAILURE,
    ],
    url: 'users/password',
    payload: {
      user: { resetPasswordToken: token, password, passwordConfirmation },
    },
    httpMethod: 'PUT',
  },
});

export const UPDATE_CURRENT_USER_REQUEST = 'UPDATE_CURRENT_USER_REQUEST';
export const UPDATE_CURRENT_USER_SUCCESS = 'UPDATE_CURRENT_USER_SUCCESS';
export const UPDATE_CURRENT_USER_FAILURE = 'UPDATE_CURRENT_USER_FAILURE';

export type UpdateCurrentUserArgsT = {
  firstName: string,
  lastName: string,
  email: string,
  username: string,
  avatarUrl: { file: File },
};

export const updateCurrentUser = ({
  firstName,
  lastName,
  email,
  username,
  avatarUrl,
}: UpdateCurrentUserArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CURRENT_USER_REQUEST,
      UPDATE_CURRENT_USER_SUCCESS,
      UPDATE_CURRENT_USER_FAILURE,
    ],
    url: `current_user`,
    httpMethod: 'PUT',
    contentType: 'formdata',
    payload: {
      user: {
        first_name: firstName,
        last_name: lastName,
        email,
        username,
        avatar: avatarUrl && avatarUrl.file ? avatarUrl.file : undefined,
      },
    },
    normalizeSchema: { user: userSchema },
  },
});

export const UPDATE_CURRENT_USER_PASSWORD_REQUEST =
  'UPDATE_CURRENT_USER_PASSWORD_REQUEST';
export const UPDATE_CURRENT_USER_PASSWORD_SUCCESS =
  'UPDATE_CURRENT_USER_PASSWORD_SUCCESS';
export const UPDATE_CURRENT_USER_PASSWORD_FAILURE =
  'UPDATE_CURRENT_USER_PASSWORD_FAILURE';

export type UpdateCurrentUserPasswordArgsT = {
  oldPassword: string,
  password: string,
  passwordConfirmation: string,
};

export const updateCurrentUserPassword = ({
  oldPassword,
  password,
  passwordConfirmation,
}: UpdateCurrentUserPasswordArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_CURRENT_USER_PASSWORD_REQUEST,
      UPDATE_CURRENT_USER_PASSWORD_SUCCESS,
      UPDATE_CURRENT_USER_PASSWORD_FAILURE,
    ],
    url: `current_user_password`,
    httpMethod: 'PUT',
    payload: {
      currentUser: {
        oldPassword,
        newPassword: password,
        newPasswordConfirmation: passwordConfirmation,
      },
    },
  },
});

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

export type UpdateUserArgsT = {
  id: string,
  firstName: string,
  lastName: string,
  email: string,
  username: string,
  avatarUrl: { file: File },
};

export const updateUser = ({
  id,
  firstName,
  lastName,
  email,
  username,
  avatarUrl,
}: UpdateUserArgsT): NetworkActionT => ({
  CALL_API: {
    types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    url: `users/${id}`,
    httpMethod: 'PUT',
    contentType: 'formdata',
    payload: {
      user: {
        id,
        first_name: firstName,
        last_name: lastName,
        email,
        username,
        avatar: avatarUrl && avatarUrl.file ? avatarUrl.file : undefined,
      },
    },
    normalizeSchema: { user: userSchema },
  },
});

export const UPDATE_USER_PASSWORD_REQUEST = 'UPDATE_USER_PASSWORD_REQUEST';
export const UPDATE_USER_PASSWORD_SUCCESS = 'UPDATE_USER_PASSWORD_SUCCESS';
export const UPDATE_USER_PASSWORD_FAILURE = 'UPDATE_USER_PASSWORD_FAILURE';

export type UpdateUserPasswordArgsT = {
  id: string,
  newPassword: string,
  newPasswordConfirmation: string,
};

export const updateUserPassword = ({
  id,
  newPassword,
  newPasswordConfirmation,
}: UpdateUserPasswordArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      UPDATE_USER_PASSWORD_REQUEST,
      UPDATE_USER_PASSWORD_SUCCESS,
      UPDATE_USER_PASSWORD_FAILURE,
    ],
    url: `users/${id}/password`,
    httpMethod: 'PUT',
    payload: {
      user: {
        newPassword,
        newPasswordConfirmation,
      },
    },
  },
});

export const UPDATE_USER_ROLE_REQUEST = 'UPDATE_USER_ROLE_REQUEST';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAILURE = 'UPDATE_USER_ROLE_FAILURE';

type UpdateUserRoleArgsT = {
  id: string,
  roleId: string,
};

export const updateUserRole =
  ({ id, roleId }: UpdateUserRoleArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          UPDATE_USER_ROLE_REQUEST,
          UPDATE_USER_ROLE_SUCCESS,
          UPDATE_USER_ROLE_FAILURE,
        ],
        url: `users/${id}/role`,
        httpMethod: 'PUT',
        payload: {
          id,
          roleId,
        },
        normalizeSchema: userSchema,
      },
    }).then(() => dispatch(getCurrentUser()));

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

type AddUserArgsT = {
  avatar: { file: File },
  firstName: string,
  lastName: string,
  username: string,
  email: string,
  password: string,
  role: string,
};

export const addUser = ({
  avatar,
  firstName,
  lastName,
  username,
  email,
  password,
  role,
}: AddUserArgsT): NetworkActionT => ({
  CALL_API: {
    types: [ADD_USER_REQUEST, ADD_USER_SUCCESS, ADD_USER_FAILURE],
    url: 'users',
    httpMethod: 'POST',
    contentType: 'formdata',
    payload: {
      user: {
        avatar: avatar && avatar.file ? avatar.file : undefined,
        first_name: firstName,
        last_name: lastName,
        email,
        username,
        password: passwordEnforcingAuthentication ? password : 'no-password',
        role_id: role,
      },
    },
    normalizeSchema: { user: userSchema },
  },
});

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const deleteUser = (id: string): NetworkActionT => ({
  CALL_API: {
    types: [DELETE_USER_REQUEST, DELETE_USER_SUCCESS, DELETE_USER_FAILURE],
    url: `users/${id}`,
    httpMethod: 'DELETE',
    payload: { id },
  },
});
