// @flow

import * as React from 'react';

/**
 * useModal
 *
 * This hook will return the same value as `show` but if show is set to `false`,
 * it will wait `animationDuration` milliseconds before setting the return value to `false` as well.
 */
export default function useModal(
  show: boolean,
  animationDuration: number = 500
) {
  const [shouldRender, setShouldRender] = React.useState(false);

  React.useEffect(() => {
    let isMounted = true;
    let timeoutId;

    if (show) {
      setShouldRender(true);
    } else {
      timeoutId = setTimeout(() => {
        if (isMounted) {
          setShouldRender(false);
        }
      }, animationDuration);
    }

    return () => {
      isMounted = false;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [show, animationDuration]);

  return shouldRender;
}
