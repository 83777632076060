// @flow

import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import activity from 'reducers/activity';
import entities from 'reducers/entities';
import errors from 'reducers/errors';
import form from 'reducers/form';
import network from 'reducers/network';
import pagination from 'reducers/paginate';
import success from 'reducers/success';

import { LOGOUT_SUCCESS } from 'actions/auth';

import auth from './auth';

const debug = process.env.NODE_ENV !== 'production';

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['access', 'refresh'],
  debug,
};

const appReducer = combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  activity,
  entities,
  errors,
  form,
  network,
  pagination,
  success,
});

export default (state: any, action: { type: string }) => {
  let newState = state;

  if (action.type === LOGOUT_SUCCESS) {
    newState = { auth: state.auth };
  }

  return appReducer(newState, action);
};
