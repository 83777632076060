// @flow

import { fromJS } from 'immutable';

import sortInline from 'utils/sort';

import {
  GET_CENTER_ACLS_SUCCESS,
  CREATE_CENTER_ACL_SUCCESS,
  UPDATE_CENTER_ACL_SUCCESS,
  DELETE_CENTER_ACL_SUCCESS,
  GET_GLOBAL_CENTER_ACLS_SUCCESS,
  CREATE_GLOBAL_CENTER_ACL_SUCCESS,
  UPDATE_GLOBAL_CENTER_ACL_SUCCESS,
  DELETE_GLOBAL_CENTER_ACL_SUCCESS,
} from 'actions/acls';

const USERS_BY_ID_PATH = ['users', 'byId'];

const CENTER_ACLS_BY_ID_PATH = ['acls', 'byId'];
const CENTER_ACLS_BY_CENTER_ID_PATH = ['acls', 'byCenterId'];

export default {
  [GET_CENTER_ACLS_SUCCESS]: (state: any, action: any): any => {
    const {
      centerId,
      response: { entities, result },
    } = action.payload;
    const { users, acls } = entities;

    return state
      .mergeDeepIn(USERS_BY_ID_PATH, fromJS(users || {}))
      .mergeDeepIn(CENTER_ACLS_BY_ID_PATH, fromJS(acls || {}))
      .setIn(
        [...CENTER_ACLS_BY_CENTER_ID_PATH, centerId],
        fromJS(result['infrastructure/centerAcls'] || [])
      );
  },
  [CREATE_CENTER_ACL_SUCCESS]: (state: any, action: any): any => {
    const {
      centerId,
      response: { entities, result },
    } = action.payload;
    const { users, acls } = entities;

    const newCenterAclIds = state
      .getIn([...CENTER_ACLS_BY_CENTER_ID_PATH, centerId])
      .push(result['infrastructure/centerAcl']);

    return state
      .mergeDeepIn(USERS_BY_ID_PATH, fromJS(users || {}))
      .mergeDeepIn(CENTER_ACLS_BY_ID_PATH, fromJS(acls || {}))
      .setIn(
        [...CENTER_ACLS_BY_CENTER_ID_PATH, centerId],
        fromJS(newCenterAclIds || [])
      );
  },
  [UPDATE_CENTER_ACL_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;
    const { acls } = entities;

    return state.mergeDeepIn(CENTER_ACLS_BY_ID_PATH, fromJS(acls || {}));
  },
  [DELETE_CENTER_ACL_SUCCESS]: (state: any, action: any): any => {
    const { aclId, centerId } = action.payload;

    const newCenterAclIds = state
      .getIn([...CENTER_ACLS_BY_CENTER_ID_PATH, centerId])
      .filter((id) => id !== aclId);

    return state
      .removeIn([...CENTER_ACLS_BY_ID_PATH, aclId])
      .setIn([...CENTER_ACLS_BY_CENTER_ID_PATH, centerId], newCenterAclIds);
  },

  [GET_GLOBAL_CENTER_ACLS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities, result },
    } = action.payload;
    const { users, acls } = entities;

    return state
      .mergeDeepIn(USERS_BY_ID_PATH, fromJS(users || {}))
      .mergeDeepIn(CENTER_ACLS_BY_ID_PATH, fromJS(acls || {}))
      .setIn(
        [...CENTER_ACLS_BY_CENTER_ID_PATH, 'global'],
        fromJS(result['infrastructure/globalCenterAcls'] || [])
      );
  },
  [CREATE_GLOBAL_CENTER_ACL_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities, result },
    } = action.payload;
    const { users, acls } = entities;

    const byAllCenters = state
      .getIn([...CENTER_ACLS_BY_CENTER_ID_PATH, 'global'])
      .push(result['infrastructure/globalCenterAcl']);

    return state
      .mergeDeepIn(USERS_BY_ID_PATH, fromJS(users || {}))
      .mergeDeepIn(CENTER_ACLS_BY_ID_PATH, fromJS(acls || {}))
      .setIn(
        [...CENTER_ACLS_BY_CENTER_ID_PATH, 'global'],
        fromJS(byAllCenters)
      );
  },
  [UPDATE_GLOBAL_CENTER_ACL_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;
    const { acls } = entities;

    return state.mergeDeepIn(CENTER_ACLS_BY_ID_PATH, fromJS(acls || {}));
  },
  [DELETE_GLOBAL_CENTER_ACL_SUCCESS]: (state: any, action: any): any => {
    const { aclId } = action.payload;

    const byAllCenters = state
      .getIn([...CENTER_ACLS_BY_CENTER_ID_PATH, 'global'])
      .filter((id) => id !== aclId);

    return state
      .removeIn([...CENTER_ACLS_BY_ID_PATH, aclId])
      .setIn([...CENTER_ACLS_BY_CENTER_ID_PATH, 'global'], byAllCenters);
  },
};

const denormalizeAcls = (state, aclIds) => {
  const {
    users: { byId: usersById },
    acls,
  } = state.entities.toJS();

  return aclIds
    .map((aclId) => {
      const acl = acls.byId[aclId];

      return {
        id: acl.id,
        user: usersById[acl.user] || undefined,
        accessType: acl.isAdmin ? 'admin' : 'member',
        receivesAlerts: acl.isAlertReceiver || false,
      };
    })
    .filter((acl) => acl.user !== undefined)
    .sort(sortInline(['user.lastName', 'user.firstName']));
};

export const centerAclByCenterIdSelector = (
  state: any,
  centerId: string
): ?Array<AclT> => {
  const { acls } = state.entities.toJS();

  if (acls.byCenterId[centerId] === undefined) return undefined;

  return denormalizeAcls(state, acls.byCenterId[centerId]);
};

export const centerAclByAllCentersSelector = (state: any): ?Array<AclT> => {
  const { acls } = state.entities.toJS();

  if (acls.byCenterId.global === undefined) return undefined;

  return denormalizeAcls(state, acls.byCenterId.global);
};
