// @flow

/* eslint-disable consistent-return */
import type { Store } from 'redux';

import { decamelizeKeys } from 'humps';
import { serialize } from 'object-to-formdata';

import { authSelector } from 'reducers/auth';

import {
  applicationError,
  applicationResponse,
  unauthorized,
  forbidden,
  notFound,
  serverError,
  queueNetworkAction,
  clearQueuedNetworkActions,
} from 'actions/network';

import { refreshAction } from 'actions/auth';

import queryParamsToUrl from 'utils/queryParamsToUrl';

export const createUrl = (urlPath: string, queryParams: ?Object) => {
  const path = urlPath.startsWith('/') ? urlPath : `/${urlPath}`;

  if (queryParams) {
    const params = queryParamsToUrl(queryParams);
    return `/api${path}${params}`;
  }

  return `/api${path}`;
};

export const getBody = (
  payload: typeof undefined | Object,
  contentType: ?string
) => {
  if (!payload) return undefined;

  if (contentType === 'formdata') {
    // NOTE: Don't try to `decamilizeKeys` as it screws up `File` objects.
    // https://github.com/domchristie/humps/pull/19
    return serialize(payload);
  }

  return JSON.stringify(decamelizeKeys(payload));
};

export const getHeaders = (headers: Object, contentType: ?string) => {
  // FORMDATA doesn't require a content type, since it includes one itself with the correct
  // boundary. e.g. `multipart/form-data; boundary=----WebKitFormBoundary8PRFhZsJKEglOgZc`
  const composedHeaders = {
    'Content-Type': contentType !== 'formdata' ? 'application/json' : undefined,
    ...headers,
  };

  const headersObject = new Headers();

  Object.keys(composedHeaders)
    .filter((key) => composedHeaders[key] !== undefined)
    .forEach((headerKey) =>
      headersObject.append(headerKey, composedHeaders[headerKey])
    );

  return headersObject;
};

export const getConfig = (
  { httpMethod, contentType, payload }: RequestDescription,
  access: ?string,
  refresh: ?string
) => {
  const httpHeaders = {};

  if (access) {
    httpHeaders.Authorization = `Bearer ${access}`;
  }

  if (refresh) {
    httpHeaders['X-Refresh-Token'] = `Bearer ${refresh}`;
  }

  return {
    method: httpMethod,
    headers: getHeaders(httpHeaders, contentType),
    body: getBody(payload, contentType),
  };
};

export default (store: Store<any, any>) =>
  (next: (action: any) => void) =>
  async (action: NetworkActionT) => {
    if (action.CALL_API === undefined) {
      return next(action);
    }

    const requestDescription: RequestDescription = action.CALL_API;
    const { types, url, params } = requestDescription;

    if (!Array.isArray(types) || types.length !== 3) {
      throw new Error('Expected an array of three action types.');
    }

    if (!types.every((type) => typeof type === 'string')) {
      throw new Error('Expected action types to be strings.');
    }

    const [requestType, successType, failureType] = types;

    next({ type: requestType });

    const state = store.getState();
    const { access, refresh } = authSelector(state);

    const endpoint = createUrl(url, params);
    const config = getConfig(requestDescription, access, refresh);

    try {
      const response = await fetch(endpoint, config);

      // The server encountered an unexpected condition that
      // prevented it from fulfilling the request.
      // https://httpstatuses.com/500
      if (response.status >= 500) {
        next({ type: failureType, payload: null, error: true });
        return next(serverError({ failureType }));
      }

      // The server understood the request but did not find the resource.
      // https://httpstatuses.com/404
      if (response.status === 404) {
        next({ type: failureType, payload: null, error: true });
        return next(notFound({ failureType }));
      }

      // The server understood the request but refuses to authorize it.
      // https://httpstatuses.com/403
      if (response.status === 403) {
        next({ type: failureType, payload: null, error: true });
        return next(forbidden({ failureType }));
      }

      // The request has not been applied because,
      // it lacks valid authentication credentials for the target resource.
      // https://httpstatuses.com/401
      if (response.status === 401) {
        if (
          requestType === 'REFRESH_TOKEN_REQUEST' ||
          requestType === 'LOGOUT_REQUEST'
        ) {
          return next(unauthorized());
        }

        if (store.getState().auth.isFetching) {
          return next(queueNetworkAction(action));
        }

        const result = await store.dispatch(refreshAction());

        if (result.type === 'UNAUTHORIZED') {
          return;
        }

        [action]
          .concat(store.getState().auth.queuedNetworkActions)
          .forEach((networkAction) => {
            store.dispatch(networkAction);
          });

        if (store.getState().auth.queuedNetworkActions.length > 0) {
          return next(clearQueuedNetworkActions());
        }

        return;
      }

      // The server has successfully fulfilled the request and
      // there is no additional content to send in the response payload body.
      // https://httpstatuses.com/204
      if (response.status === 204) {
        return next(applicationResponse(successType, requestDescription, null));
      }

      let data = null;

      try {
        data = await response.json();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      if (!response.ok) {
        return next(applicationError(failureType, requestDescription, data));
      }

      return next(applicationResponse(successType, requestDescription, data));
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
