// @flow

import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import t from './translations';

type Props = {
  onSubmit: ({ username: string, password: string }) => void,
};

const LoginForm = ({ onSubmit }: Props) => {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const { formatMessage } = useIntl();

  const handleChange = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event: SyntheticInputEvent<HTMLButtonElement>) => {
    event.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      <label htmlFor="username" className="sr-only">
        {formatMessage(t.username)}
      </label>
      <input
        autoFocus
        type="text"
        id="username"
        name="username"
        className="form-control"
        placeholder={formatMessage(t.username)}
        value={formData.username}
        onChange={handleChange}
      />

      <label htmlFor="password" className="sr-only">
        {formatMessage(t.password)}
      </label>
      <input
        type="password"
        id="password"
        name="password"
        className="form-control"
        placeholder={formatMessage(t.password)}
        value={formData.password}
        onChange={handleChange}
      />

      <button className="button button-primary button-block" type="submit">
        {formatMessage(t.loginSubmit)}
      </button>
    </form>
  );
};

export default LoginForm;
