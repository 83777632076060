// @flow

// $FlowFixMe
import { useLocation } from 'react-router-dom';
import qs from 'qs';

const useQueryString = () => {
  const { search } = useLocation();
  const params = qs.parse(search, { ignoreQueryPrefix: true });

  return params;
};

export default useQueryString;
