// @flow

import { schema } from 'normalizr';
import type { Dispatch } from 'redux';

import createSerializer, { serializeTime } from 'utils/schemaSerializers';
import { userSchema, getCurrentUser } from 'actions/users';

const aclSchema = new schema.Entity(
  'acls',
  {
    user: userSchema,
  },
  {
    processStrategy: createSerializer({
      updatedAt: serializeTime,
      createdAt: serializeTime,
    }),
  }
);

export const GET_CENTER_ACLS_REQUEST = 'GET_CENTER_ACLS_REQUEST';
export const GET_CENTER_ACLS_SUCCESS = 'GET_CENTER_ACLS_SUCCESS';
export const GET_CENTER_ACLS_FAILURE = 'GET_CENTER_ACLS_FAILURE';

export type GetCenterAclsArgsT = {
  centerId: string,
};

export const getCenterAcls = ({
  centerId,
}: GetCenterAclsArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_CENTER_ACLS_REQUEST,
      GET_CENTER_ACLS_SUCCESS,
      GET_CENTER_ACLS_FAILURE,
    ],
    url: `centers/${centerId}/acl`,
    httpMethod: 'GET',
    actionPayload: { centerId },
    normalizeSchema: { 'infrastructure/centerAcls': [aclSchema] },
  },
});

export const GET_GLOBAL_CENTER_ACLS_REQUEST = 'GET_GLOBAL_CENTER_ACLS_REQUEST';
export const GET_GLOBAL_CENTER_ACLS_SUCCESS = 'GET_GLOBAL_CENTER_ACLS_SUCCESS';
export const GET_GLOBAL_CENTER_ACLS_FAILURE = 'GET_GLOBAL_CENTER_ACLS_FAILURE';

export const getGlobalCenterAcls = (): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_GLOBAL_CENTER_ACLS_REQUEST,
      GET_GLOBAL_CENTER_ACLS_SUCCESS,
      GET_GLOBAL_CENTER_ACLS_FAILURE,
    ],
    url: `global_center_acl`,
    httpMethod: 'GET',
    normalizeSchema: { 'infrastructure/globalCenterAcls': [aclSchema] },
  },
});

export const CREATE_CENTER_ACL_REQUEST = 'CREATE_CENTER_ACL_REQUEST';
export const CREATE_CENTER_ACL_SUCCESS = 'CREATE_CENTER_ACL_SUCCESS';
export const CREATE_CENTER_ACL_FAILURE = 'CREATE_CENTER_ACL_FAILURE';

export type CreateCenterAclArgsT = {
  centerId: string,
  userId: string,
  isAdmin: boolean,
  isAlertReceiver: boolean,
};

export const createCenterAcl =
  ({ centerId, userId, isAdmin, isAlertReceiver }: CreateCenterAclArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          CREATE_CENTER_ACL_REQUEST,
          CREATE_CENTER_ACL_SUCCESS,
          CREATE_CENTER_ACL_FAILURE,
        ],
        url: `centers/${centerId}/acl`,
        httpMethod: 'POST',
        payload: { centerAcl: { userId, isAdmin, isAlertReceiver } },
        actionPayload: { centerId },
        normalizeSchema: { 'infrastructure/centerAcl': aclSchema },
      },
    }).then(() => dispatch(getCurrentUser()));

export const CREATE_GLOBAL_CENTER_ACL_REQUEST =
  'CREATE_GLOBAL_CENTER_ACL_REQUEST';
export const CREATE_GLOBAL_CENTER_ACL_SUCCESS =
  'CREATE_GLOBAL_CENTER_ACL_SUCCESS';
export const CREATE_GLOBAL_CENTER_ACL_FAILURE =
  'CREATE_GLOBAL_CENTER_ACL_FAILURE';

export type CreateGlobalCenterAclArgsT = {
  userId: string,
  isAdmin: boolean,
  isAlertReceiver: boolean,
};

export const createGlobalCenterAcl =
  ({ userId, isAdmin, isAlertReceiver }: CreateGlobalCenterAclArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          CREATE_GLOBAL_CENTER_ACL_REQUEST,
          CREATE_GLOBAL_CENTER_ACL_SUCCESS,
          CREATE_GLOBAL_CENTER_ACL_FAILURE,
        ],
        url: `global_center_acl`,
        httpMethod: 'POST',
        payload: { centerAcl: { userId, isAdmin, isAlertReceiver } },
        normalizeSchema: { 'infrastructure/globalCenterAcl': aclSchema },
      },
    }).then(() => dispatch(getCurrentUser()));

export const UPDATE_CENTER_ACL_REQUEST = 'UPDATE_CENTER_ACL_REQUEST';
export const UPDATE_CENTER_ACL_SUCCESS = 'UPDATE_CENTER_ACL_SUCCESS';
export const UPDATE_CENTER_ACL_FAILURE = 'UPDATE_CENTER_ACL_FAILURE';

export type UpdateCenterAclArgsT = {
  id: string,
  centerId: string,
  isAdmin: boolean,
  isAlertReceiver: boolean,
};

export const updateCenterAcl =
  ({ id, centerId, isAdmin, isAlertReceiver }: UpdateCenterAclArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          UPDATE_CENTER_ACL_REQUEST,
          UPDATE_CENTER_ACL_SUCCESS,
          UPDATE_CENTER_ACL_FAILURE,
        ],
        url: `centers/${centerId}/acl/${id}`,
        httpMethod: 'PUT',
        payload: { centerAcl: { isAdmin, isAlertReceiver } },
        normalizeSchema: { 'infrastructure/centerAcl': aclSchema },
      },
    }).then(() => dispatch(getCurrentUser()));

export const UPDATE_GLOBAL_CENTER_ACL_REQUEST =
  'UPDATE_GLOBAL_CENTER_ACL_REQUEST';
export const UPDATE_GLOBAL_CENTER_ACL_SUCCESS =
  'UPDATE_GLOBAL_CENTER_ACL_SUCCESS';
export const UPDATE_GLOBAL_CENTER_ACL_FAILURE =
  'UPDATE_GLOBAL_CENTER_ACL_FAILURE';

export type UpdateGlobalCenterAclArgsT = {
  id: string,
  isAdmin: boolean,
  isAlertReceiver: boolean,
};

export const updateGlobalCenterAcl =
  ({ id, isAdmin, isAlertReceiver }: UpdateGlobalCenterAclArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          UPDATE_GLOBAL_CENTER_ACL_REQUEST,
          UPDATE_GLOBAL_CENTER_ACL_SUCCESS,
          UPDATE_GLOBAL_CENTER_ACL_FAILURE,
        ],
        url: `global_center_acl/${id}`,
        httpMethod: 'PUT',
        payload: { centerAcl: { isAdmin, isAlertReceiver } },
        normalizeSchema: { 'infrastructure/globalCenterAcl': aclSchema },
      },
    }).then(() => dispatch(getCurrentUser()));

export const DELETE_CENTER_ACL_REQUEST = 'DELETE_CENTER_ACL_REQUEST';
export const DELETE_CENTER_ACL_SUCCESS = 'DELETE_CENTER_ACL_SUCCESS';
export const DELETE_CENTER_ACL_FAILURE = 'DELETE_CENTER_ACL_FAILURE';

export type DeleteCenterAclArgsT = {
  id: string,
  centerId: string,
};

export const deleteCenterAcl =
  ({ id, centerId }: DeleteCenterAclArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          DELETE_CENTER_ACL_REQUEST,
          DELETE_CENTER_ACL_SUCCESS,
          DELETE_CENTER_ACL_FAILURE,
        ],
        url: `centers/${centerId}/acl/${id}`,
        httpMethod: 'DELETE',
        actionPayload: { aclId: id, centerId },
      },
    }).then(() => dispatch(getCurrentUser()));

export const DELETE_GLOBAL_CENTER_ACL_REQUEST =
  'DELETE_GLOBAL_CENTER_ACL_REQUEST';
export const DELETE_GLOBAL_CENTER_ACL_SUCCESS =
  'DELETE_GLOBAL_CENTER_ACL_SUCCESS';
export const DELETE_GLOBAL_CENTER_ACL_FAILURE =
  'DELETE_GLOBAL_CENTER_ACL_FAILURE';

export type DeleteGlobalCenterAclArgsT = {
  id: string,
};

export const deleteGlobalCenterAcl =
  ({ id }: DeleteGlobalCenterAclArgsT) =>
  (dispatch: Dispatch<Object>) =>
    dispatch({
      CALL_API: {
        types: [
          DELETE_GLOBAL_CENTER_ACL_REQUEST,
          DELETE_GLOBAL_CENTER_ACL_SUCCESS,
          DELETE_GLOBAL_CENTER_ACL_FAILURE,
        ],
        url: `global_center_acl/${id}`,
        httpMethod: 'DELETE',
        actionPayload: { aclId: id },
      },
    }).then(() => dispatch(getCurrentUser()));
