// @flow

import * as React from 'react';

type Props = {
  children?: React.Node,
  className?: string,
};

const Main = ({ className, children }: Props) => (
  <main className={className}>{children}</main>
);

export default Main;
