// @flow

import React from 'react';
import { connect } from 'react-redux';
import md5 from 'blueimp-md5';

import Flash from 'components/Flash';
import Section from 'components/Section';

type Props = {
  substituteMessage?: string,
  close: boolean,
  activity: ActivityT,
  errors: ErrorsT,
};

const ErrorFlash = ({
  close = true,
  activity,
  errors,
  substituteMessage,
}: Props) => {
  if (
    !activity.name &&
    errors.length > 0 &&
    errors.find((e) => e.handlerType === 'form')
  ) {
    return errors.map((err) => (
      <Section key={md5(err.title)}>
        <Flash
          close={close}
          error
          message={err.detail || substituteMessage || ''}
        />
      </Section>
    ));
  }

  return null;
};

const mapStateToProps = (state) => ({
  activity: state.activity,
  errors: state.errors,
});

ErrorFlash.displayName = 'ErrorFlash';

export default connect(mapStateToProps, null)(ErrorFlash);
