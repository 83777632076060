// @flow

import { fromJS } from 'immutable';

import { GET_DEVICE_PENDING_MESSAGES_SUCCESS } from 'actions/devices';

const DEVICE_PENDING_MESSAGE_DATA_PATH = ['devicePendingMessages', 'data'];
const DEVICE_PENDING_MESSAGE_PAGINATION_PATH = [
  'devicePendingMessages',
  'pagination',
  'currentPage',
];

export default {
  [GET_DEVICE_PENDING_MESSAGES_SUCCESS]: (state: any, action: any): any => {
    const {
      page,
      deviceId,
      response: {
        entities: { pendingMessages },
      },
    } = action.payload;

    return state
      .mergeDeepIn(
        [...DEVICE_PENDING_MESSAGE_DATA_PATH, deviceId, page],
        fromJS(pendingMessages || {})
      )
      .setIn(DEVICE_PENDING_MESSAGE_PAGINATION_PATH, page);
  },
};

export const devicePendingMessagesByPageSelector = (
  state: any,
  deviceId: string
): ?Object => {
  const {
    devicePendingMessages: {
      data,
      pagination: { currentPage },
    },
  } = state.entities.toJS();

  if (data[deviceId] === undefined || data[deviceId][currentPage] === undefined)
    return undefined;

  const pendingMessages = data[deviceId][currentPage];

  return Object.keys(pendingMessages).reduce(
    (acc, key) => ({
      ...acc,
      ...pendingMessages[key],
    }),
    {}
  );
};
