// @flow

/* eslint-disable max-classes-per-file, class-methods-use-this */

import * as Msal from 'msal';
import { camelizeKeys } from 'humps';

type UserSession = { access: string, refresh: string };

export type Credentials =
  | { username: string, password: string }
  | { id_token: string }
  | null;

export interface Authenticator {
  login(Credentials): Promise<UserSession>;
  logout(): void;
}

const authenticate = async (credentials: Credentials): Promise<UserSession> => {
  const response = await fetch('/api/auth', {
    method: 'POST',
    body: JSON.stringify(credentials),
    headers: { 'content-type': 'application/json' },
  });

  const data = await response.json();

  if (response.status === 401) {
    throw new Error(data.errors?.[0]?.title ?? response.statusText);
  }

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return camelizeKeys(data);
};

class MicrosoftAuthenticator implements Authenticator {
  userAgentApplication = new Msal.UserAgentApplication({
    auth: {
      authority: process.env.REACT_APP_MSAL_AUTHORITY,
      clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
      redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
    },
    cache: {
      cacheLocation: 'localStorage',
    },
  });

  // eslint-disable-next-line no-unused-vars
  async login(credentials: Credentials): Promise<UserSession> {
    const response = await this.userAgentApplication.loginPopup(['email']);
    const idToken = response.idToken.rawIdToken;

    return authenticate({ id_token: idToken });
  }

  logout(): void {
    this.userAgentApplication.logout();
  }
}

class UsernamePasswordAuthenticator implements Authenticator {
  login(credentials: Credentials): Promise<UserSession> {
    return authenticate(credentials);
  }

  logout(): void {}
}

export const authenticatorType = process.env.REACT_APP_AUTHENTICATOR;

export const passwordEnforcingAuthentication = !(authenticatorType === 'oidc');

export const authenticator =
  authenticatorType === 'oidc'
    ? new MicrosoftAuthenticator()
    : new UsernamePasswordAuthenticator();
