import createReducer from 'reducers/createReducer';
import getType from 'utils/getType';

const initialState = {};

export const setNetworkActive = (state, { type }) => ({
  ...state,
  [getType(type)]: true,
});

export const resetNetwork = (state, { type }) => ({
  ...state,
  [getType(type)]: false,
});

const detector = (type) => {
  if (type && type.indexOf('REQUEST') > -1) return setNetworkActive;
  if (type && type.indexOf('SUCCESS') > -1) return resetNetwork;
  if (type && type.indexOf('FAILURE') > -1) return resetNetwork;
  return (state) => state;
};

export default createReducer(detector, initialState);
