// @flow

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { NavLink } from 'react-router-dom';

const t = defineMessages({
  dashboard: {
    id: 'wts_navigation_dashboard',
    defaultMessage: 'Dashboard',
  },
});

const Navigation = () => {
  const { formatMessage } = useIntl();

  return (
    <li role="presentation">
      <NavLink
        to="/wts"
        activeClassName="is-active"
        title={formatMessage(t.dashboard)}
      >
        <i className="material-icons">devices</i>
      </NavLink>
    </li>
  );
};

export default Navigation;
