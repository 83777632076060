import { fromJS } from 'immutable';

import createReducer from 'reducers/createReducer';

import users from './users';
import roles from './roles';
import rights from './rights';
import centers from './centers';
import devices from './devices';
import connectors from './connectors';
import acls from './acls';
import tenants from './tenants';
import configurations from './configurations';

const dataUpdaters = {
  ...users,
  ...roles,
  ...rights,
  ...centers,
  ...devices,
  ...connectors,
  ...acls,
  ...tenants,
  ...configurations,
};

export const initialState = fromJS({
  acls: { byId: {}, byCenterId: {} },
  centers: {},
  configurations: {},
  connectors: {},
  connectorToken: null,
  connectorDeviceConfigs: {},
  deviceAttachments: { byId: {}, byDeviceId: {} },
  deviceAlerts: { byId: {}, byDeviceId: {} },
  deviceConfigurations: { byId: {}, byDeviceId: {} },
  devicesDashboard: {
    tenants: null,
    centers: null,
    devices: {},
    filters: null,
  },
  deviceData: {},
  deviceModels: [],
  devicePendingMessages: { data: {}, pagination: { currentPage: 0 } },
  deviceLogs: { byId: {}, byDeviceId: {} },
  deviceIntervals: {},
  deviceSummaries: {},
  devices: { byId: {}, searchResults: {} },
  heatDisinfectionCycles: { byId: {}, byDeviceId: {} },
  heatDisinfectionData: {},
  rights: {},
  roles: {},
  sharedTenants: {},
  users: { byId: {}, byResult: [] },
});

const detector = (type) => {
  if (dataUpdaters[type]) return dataUpdaters[type];

  if (type.includes('SUCCESS') && process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.warn(`Unhandeled action: ${type}`);
  }

  return (state) => state;
};

export default createReducer(detector, initialState);
