// @flow

import { normalize } from 'normalizr';
import { camelizeKeys, camelize } from 'humps';

export const UNAUTHORIZED = 'UNAUTHORIZED';
export const unauthorized = () => ({
  type: UNAUTHORIZED,
  payload: null,
  error: true,
});

export const FORBIDDEN = 'FORBIDDEN';
export const forbidden = ({ failureType }: { failureType: string }) => ({
  type: FORBIDDEN,
  payload: { failedAction: failureType },
  error: true,
});

export const SERVER_ERROR = 'SERVER_ERROR';
export const serverError = ({ failureType }: { failureType: string }) => ({
  type: SERVER_ERROR,
  payload: { failedAction: failureType },
  error: true,
});

export const NOT_FOUND = 'NOT_FOUND';
export const notFound = ({ failureType }: { failureType: string }) => ({
  type: NOT_FOUND,
  payload: { failedAction: failureType },
  error: true,
});

export const QUEUE_NETWORK_ACTION = 'QUEUE_NETWORK_ACTION';
export const queueNetworkAction = (action: NetworkActionT) => ({
  type: QUEUE_NETWORK_ACTION,
  payload: action,
  error: false,
});

export const CLEAR_QUEUED_NETWORK_ACTIONS = 'CLEAR_QUEUED_NETWORK_ACTIONS';
export const clearQueuedNetworkActions = () => ({
  type: CLEAR_QUEUED_NETWORK_ACTIONS,
  payload: null,
  error: false,
});

export const applicationError = (
  type: string,
  rd: RequestDescription,
  json: Object
) => {
  if (Array.isArray(json.errors)) {
    const errors = camelizeKeys(json).errors.map((error) => ({
      source: error.source ? camelize(error.source) : undefined,
      title: error.title ? error.title : undefined,
      detail: error.detail ? error.detail : undefined,
      meta: {
        request: rd.params || rd.payload,
      },
    }));

    return { type, payload: errors, error: true };
  }

  if ('error' in json) return { type, payload: json.error, error: true };
  return { type, payload: json.errors, error: true };
};

export const applicationResponse = (
  type: string,
  rd: RequestDescription,
  json: ?Object
) => {
  const camelizedJSON = camelizeKeys(json);

  const response = rd.normalizeSchema
    ? normalize(camelizedJSON, rd.normalizeSchema)
    : camelizedJSON;

  const actionPayload = rd.actionPayload || {};

  return {
    type,
    payload: {
      ...actionPayload,
      request: rd.params || rd.payload,
      response,
    },
    error: false,
  };
};
