// @flow

import * as React from 'react';
import * as Sentry from '@sentry/browser';

import ErrorView from 'views/Error';

type Props = {
  children: React.Node,
};

type State = {
  error: ?Error,
};

class ErrorBoundary extends React.Component<Props, State> {
  state = { error: null };

  componentDidCatch(error: Error, errorInfo: Object) {
    this.setState({ error });

    Sentry.withScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key]);
      });

      Sentry.captureException(error);
    });
  }

  render() {
    const { children } = this.props;
    const { error } = this.state;

    if (error) {
      return <ErrorView onClickCallBack={() => Sentry.showReportDialog()} />;
    }

    return children;
  }
}

export default ErrorBoundary;
