// @flow

import {
  GET_SHARED_TENANTS_SUCCESS,
  UPDATE_SHARED_TENANTS_SUCCESS,
} from 'actions/tenants';

const SHARED_TENANTS_PATH = ['sharedTenants'];

export default {
  [GET_SHARED_TENANTS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { share },
    } = action.payload;

    return state.setIn([...SHARED_TENANTS_PATH, 'dwa'], share);
  },
  [UPDATE_SHARED_TENANTS_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { share },
    } = action.payload;

    return state.setIn([...SHARED_TENANTS_PATH, 'dwa'], share);
  },
};

export const sharedTenantsSelector = (
  state: any
): ?Array<{ name: string, value: string }> => {
  const { sharedTenants } = state.entities.toJS();

  if (sharedTenants.dwa === undefined) return [];

  return Object.keys(sharedTenants).map((tenant) => ({
    name: tenant.toUpperCase(),
    value: sharedTenants[tenant],
  }));
};
