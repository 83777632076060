// @flow

import { schema } from 'normalizr';

import createSerializer, { serializeTime } from 'utils/schemaSerializers';

import { userSchema } from 'actions/users';

const deviceAttachmentSchema = new schema.Entity(
  'deviceAttachments',
  { uploadedBy: userSchema },
  {
    processStrategy: createSerializer({
      createdAt: serializeTime,
    }),
  }
);

export const GET_DEVICE_ATTACHMENTS_REQUEST = 'GET_DEVICE_ATTACHMENTS_REQUEST';
export const GET_DEVICE_ATTACHMENTS_SUCCESS = 'GET_DEVICE_ATTACHMENTS_SUCCESS';
export const GET_DEVICE_ATTACHMENTS_FAILURE = 'GET_DEVICE_ATTACHMENTS_FAILURE';

export const getDeviceAttachments = (deviceId: string): NetworkActionT => ({
  CALL_API: {
    types: [
      GET_DEVICE_ATTACHMENTS_REQUEST,
      GET_DEVICE_ATTACHMENTS_SUCCESS,
      GET_DEVICE_ATTACHMENTS_FAILURE,
    ],
    url: `/devices/${deviceId}/attachments`,
    httpMethod: 'GET',
    actionPayload: { deviceId },
    normalizeSchema: {
      deviceAttachments: [deviceAttachmentSchema],
    },
  },
});

export const ADD_DEVICE_ATTACHMENT_REQUEST = 'ADD_DEVICE_ATTACHMENT_REQUEST';
export const ADD_DEVICE_ATTACHMENT_SUCCESS = 'ADD_DEVICE_ATTACHMENT_SUCCESS';
export const ADD_DEVICE_ATTACHMENT_FAILURE = 'ADD_DEVICE_ATTACHMENT_FAILURE';

export type AddDeviceAttachmentArgsT = {
  deviceId: string,
  name: string,
  note: string,
  attachment: { file: File },
};

export const addDeviceAttachment = ({
  deviceId,
  name,
  note,
  attachment,
}: AddDeviceAttachmentArgsT): NetworkActionT => ({
  CALL_API: {
    types: [
      ADD_DEVICE_ATTACHMENT_REQUEST,
      ADD_DEVICE_ATTACHMENT_SUCCESS,
      ADD_DEVICE_ATTACHMENT_FAILURE,
    ],
    url: `/devices/${deviceId}/attachments`,
    httpMethod: 'POST',
    contentType: 'formdata',
    payload: {
      device_attachment: {
        attachment: attachment.file,
        note,
        name,
      },
    },
    actionPayload: { deviceId },
    normalizeSchema: { deviceAttachment: deviceAttachmentSchema },
  },
});

export const DELETE_DEVICE_ATTACHMENT_REQUEST =
  'DELETE_DEVICE_ATTACHMENT_REQUEST';
export const DELETE_DEVICE_ATTACHMENT_SUCCESS =
  'DELETE_DEVICE_ATTACHMENT_SUCCESS';
export const DELETE_DEVICE_ATTACHMENT_FAILURE =
  'DELETE_DEVICE_ATTACHMENT_FAILURE';

export const deleteDeviceAttachment = (
  id: string,
  deviceId: string
): NetworkActionT => ({
  CALL_API: {
    types: [
      DELETE_DEVICE_ATTACHMENT_REQUEST,
      DELETE_DEVICE_ATTACHMENT_SUCCESS,
      DELETE_DEVICE_ATTACHMENT_FAILURE,
    ],
    url: `devices/${deviceId}/attachments/${id}`,
    httpMethod: 'DELETE',
    actionPayload: { id, deviceId },
  },
});
