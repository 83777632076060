// @flow

import * as React from 'react';
import classnames from 'classnames';

type Props = {
  icon: string,
  className?: string,
  style?: Object,
  title?: string,
  disabled?: boolean,

  success?: boolean,
  warning?: boolean,
  danger?: boolean,
  primary?: boolean,

  spinning?: boolean,

  onClick?: (event: Object) => void,
  onBlur?: (event: Object) => void,
  onMouseEnter?: (event: Object) => void,
  onMouseLeave?: (event: Object) => void,
};

const ActionButton = React.forwardRef<Props, HTMLButtonElement>(
  (
    {
      icon,
      success,
      warning,
      danger,
      primary,
      spinning,
      className,
      style,
      title,
      disabled = false,
      onClick,
      onBlur,
      onMouseEnter,
      onMouseLeave,
    }: Props,
    ref
  ) => {
    const classNames = classnames('action', className, {
      'action-success': success,
      'action-warning': warning,
      'action-danger': danger,
      'action-primary': primary,
      'is-spinning': spinning,
    });

    return (
      <button
        ref={ref}
        type="button"
        className={classNames}
        onClick={onClick}
        onBlur={onBlur}
        onMouseLeave={onMouseLeave}
        onMouseEnter={onMouseEnter}
        style={style}
        title={title}
        disabled={disabled}
      >
        <i className="material-icons">{icon}</i>
      </button>
    );
  }
);

ActionButton.displayName = 'ActionButton';

export default ActionButton;
