// @flow

import * as React from 'react';
import classNames from 'classnames';

import Loading from 'components/Loading';

type Props = {
  title?: string | React.Node,
  actionButton?: React.Element<any> | Array<Object>,
  children?: React.Node,
  footer?: React.Node,
  className?: string,
  isLoading?: boolean,
  contentClassName?: string,
  style?: Object,
  onClick?: (e: SyntheticEvent<HTMLElement>) => void,
};

const Section = ({
  title,
  actionButton,
  children,
  footer,
  className,
  style,
  contentClassName,
  isLoading = false,
  onClick,
}: Props) => {
  if (!title)
    return (
      <section
        role="presentation"
        className={className}
        style={style}
        onClick={onClick}
      >
        {!isLoading ? (
          children
        ) : (
          <div style={{ height: 150, position: 'relative' }}>
            <Loading />
          </div>
        )}
      </section>
    );

  return (
    <section
      role="presentation"
      className={className}
      style={style}
      onClick={onClick}
    >
      <h4 className="section-title">
        {title}
        <div className="section-actions">{actionButton}</div>
      </h4>

      <div className={classNames('section-content', contentClassName)}>
        {!isLoading ? (
          children
        ) : (
          <div style={{ height: 150, position: 'relative' }}>
            <Loading />
          </div>
        )}
      </div>

      {footer && !isLoading ? (
        <div className="section-footer">{footer}</div>
      ) : null}
    </section>
  );
};

export default Section;
