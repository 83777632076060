// @flow

import * as React from 'react';
import { NavLink, Switch, Route } from 'react-router-dom';

// $FlowIgnore
import { ReactComponent as Logo } from 'assets/images/dwa-logo.svg';

import NavbarCurrentUser from './NavbarCurrentUser';
import NavbarSignOut from './NavbarSignOut';

const LogoLink = ({ url }) => (
  <NavLink to={url} className="navbar-logo">
    <Logo />
  </NavLink>
);

const NavbarRight = ({ perspective }) => (
  <div className="navbar-right">
    <NavbarCurrentUser perspective={perspective} />
    <NavbarSignOut />
  </div>
);

const NavbarLeft = ({ perspective }) => (
  <div className="navbar-left">
    <LogoLink url={`/${perspective}`} />
  </div>
);

const Shell = ({ perspective }) => (
  <nav className="navbar">
    <NavbarLeft perspective={perspective} />
    <NavbarRight perspective={perspective} />
  </nav>
);

const Navbar = () => (
  <Switch>
    <Route path="/wts" render={() => <Shell perspective="wts" />} />
    <Route path="/admin" render={() => <Shell perspective="admin" />} />
  </Switch>
);

export default Navbar;
