// @flow

import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import useQueryString from 'hooks/useQueryString';

import { unlockAccount } from 'actions/users';

import ErrorFlash from 'components/containers/ErrorFlash';

import t from './translations';
import useActionReset from './useActionReset';
import Layout from './Layout';

export default () => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();

  const { token } = useQueryString();
  const actionReset = useActionReset();
  useEffect(() => {
    dispatch(unlockAccount({ token }));
    return actionReset;
  }, [actionReset, dispatch, token]);

  const success = useSelector((state) => state.success);

  return (
    <Layout>
      <h2 className="signin-heading">
        <FormattedMessage {...t.accountUnlock} />
      </h2>

      <ErrorFlash
        close={false}
        substituteMessage={formatMessage(t.errorMessageFallback)}
      />

      {success.type === 'UNLOCK_ACCOUNT' ? (
        <p>{formatMessage(t.resetSuccessMessage)}</p>
      ) : null}

      <Link style={{ marginTop: '10px' }} to="/login">
        {formatMessage(t.returnLogin)}
      </Link>
    </Layout>
  );
};
