// @flow

import { fromJS } from 'immutable';

import {
  GET_DEVICES_DASHBOARD_SUCCESS,
  GET_DEVICE_INFO_SUCCESS,
} from 'actions/devices';

import sortInline from 'utils/sort';

const DEVICE_DASHBOARD_PATH = ['devicesDashboard'];

export default {
  [GET_DEVICES_DASHBOARD_SUCCESS]: (state: any, action: any): any => {
    const {
      response: { entities },
    } = action.payload;

    return state
      .setIn(
        [...DEVICE_DASHBOARD_PATH, 'filters'],
        fromJS(entities.filters || {})
      )
      .setIn(
        [...DEVICE_DASHBOARD_PATH, 'tenants'],
        fromJS(entities.tenants || {})
      )
      .setIn(
        [...DEVICE_DASHBOARD_PATH, 'centers'],
        fromJS(entities.centers || {})
      )
      .setIn(
        [...DEVICE_DASHBOARD_PATH, 'devices'],
        fromJS(entities.devices || {})
      );
  },
  [GET_DEVICE_INFO_SUCCESS]: (state: any, action: any): any => {
    const {
      response: {
        entities: { devices },
      },
    } = action.payload;

    return state.mergeIn(
      [...DEVICE_DASHBOARD_PATH, 'devices'],
      fromJS(devices || {})
    );
  },
};

export const devicesDataSelector = (
  state: any
): ?Array<DeviceDashboardDataT> => {
  const {
    devicesDashboard: { tenants, centers, devices },
  } = state.entities.toJS();

  if (!tenants || !centers || !devices) return undefined;

  const tenantDevicesWithErrorStatusCountFn = (tenantCenters) =>
    tenantCenters.reduce(
      (acc, center) =>
        acc +
        center.devices.filter((device) => device.status.color === 'red').length,
      0
    );
  const centerDevicesWithErrorStatusCountFn = (centerDevices) =>
    centerDevices.filter((device) => device.status.color === 'red').length;

  const sortTenantsByDeviceErrorsFn = (tenantA, tenantB) => {
    const tenantADevicesWithErrorStatusCount =
      tenantDevicesWithErrorStatusCountFn(tenantA.centers);

    const tenantBDevicesWithErrorStatusCount =
      tenantDevicesWithErrorStatusCountFn(tenantB.centers);

    if (tenantADevicesWithErrorStatusCount > tenantBDevicesWithErrorStatusCount)
      return -1;

    if (tenantADevicesWithErrorStatusCount < tenantBDevicesWithErrorStatusCount)
      return 1;

    return 0;
  };

  const sortCentersByDeviceErrorsFn = (centerA, centerB) => {
    const centerADevicesWithErrorsCount = centerDevicesWithErrorStatusCountFn(
      centerA.devices
    );

    const centerBDevicesWithErrorsCount = centerDevicesWithErrorStatusCountFn(
      centerB.devices
    );

    if (centerADevicesWithErrorsCount > centerBDevicesWithErrorsCount)
      return -1;

    if (centerADevicesWithErrorsCount < centerBDevicesWithErrorsCount) return 1;

    return 0;
  };

  const deindexedTenants = Object.keys(tenants).map((tenantId) => {
    const tenant = tenants[tenantId];

    const tenantCenters = tenant.centers.map((centerId) => {
      const center = centers[centerId];
      const centerDevices = center.devices.map((deviceId) => devices[deviceId]);

      const devicesWithErrors = centerDevices
        .filter((d) => d.status.color === 'red')
        .sort(sortInline('name', 'ascending'));

      const devicesWithoutErrors = centerDevices
        .filter((d) => d.status.color !== 'red')
        .sort(sortInline('name', 'ascending'));

      return {
        ...center,
        devices: [...devicesWithErrors, ...devicesWithoutErrors],
      };
    });

    const centersWithDeviceErrors = tenantCenters
      .filter(
        (center) => centerDevicesWithErrorStatusCountFn(center.devices) > 0
      )
      .sort(sortInline('name', 'ascending'))
      .sort(sortCentersByDeviceErrorsFn);

    const centersWithoutDeviceErrors = tenantCenters
      .filter(
        (center) => centerDevicesWithErrorStatusCountFn(center.devices) === 0
      )
      .sort(sortInline('name', 'ascending'));

    return {
      ...tenant,
      centers: [...centersWithDeviceErrors, ...centersWithoutDeviceErrors],
    };
  });

  const tenantsWithDeviceErrors = deindexedTenants
    .filter((tenant) => tenantDevicesWithErrorStatusCountFn(tenant.centers) > 0)
    .sort(sortInline('name', 'ascending'))
    .sort(sortTenantsByDeviceErrorsFn);

  const tenantsWithoutDeviceErrors = deindexedTenants
    .filter(
      (tenant) => tenantDevicesWithErrorStatusCountFn(tenant.centers) === 0
    )
    .sort(sortInline('name', 'ascending'));

  return [...tenantsWithDeviceErrors, ...tenantsWithoutDeviceErrors];
};

export const devicesFilterSelector = (
  state: any
): ?Array<DeviceDashboardFilterT> => {
  const {
    devicesDashboard: { filters },
  } = state.entities.toJS();

  if (!filters) return undefined;

  return Object.keys(filters).map((key) => {
    const filter = filters[key];

    return filter.options
      ? {
          ...filter,
          options: filter.options.map((option) => ({
            value: option.id,
            label: option.name,
          })),
        }
      : filter;
  });
};

export const deviceInfoSelector = (
  state: any,
  deviceId: ?string
): ?DeviceDashboardT => {
  if (!deviceId) return undefined;

  const {
    devicesDashboard: { devices },
  } = state.entities.toJS();

  if (!devices) return undefined;

  return devices[deviceId];
};
