import createReducer from 'reducers/createReducer';

import { LOCATION_CHANGE } from 'actions/router';
import { RESET_ERROR } from 'actions/error';
import { SET_CLIENT_FORM_ERRORS } from 'actions/form';
import { FORBIDDEN, NOT_FOUND } from 'actions/network';

import {
  CREATE_ROLE_FAILURE,
  UPDATE_ROLE_FAILURE,
  UPDATE_RIGHTS_FOR_ROLE_FAILURE,
} from 'actions/permissions';

import {
  REQUEST_PASSWORD_RESET_FAILURE,
  RESET_PASSWORD_FAILURE,
  REQUEST_ACCOUNT_UNLOCK_FAILURE,
  UNLOCK_ACCOUNT_FAILURE,
  ADD_USER_FAILURE,
  UPDATE_CURRENT_USER_FAILURE,
  UPDATE_CURRENT_USER_PASSWORD_FAILURE,
  UPDATE_USER_FAILURE,
  UPDATE_USER_PASSWORD_FAILURE,
  UPDATE_USER_ROLE_FAILURE,
} from 'actions/users';

import {
  ADD_CENTER_FAILURE,
  UPDATE_CENTER_FAILURE,
  DELETE_CENTER_FAILURE,
} from 'actions/centers';

import {
  ADD_DEVICE_FAILURE,
  UPDATE_DEVICE_FAILURE,
  GET_DEVICE_FAILURE,
} from 'actions/devices';

import { UPDATE_CONNECTOR_FAILURE } from 'actions/connectors';

import {
  ADD_CONNECTOR_DEVICE_CONFIG_FAILURE,
  UPDATE_CONNECTOR_DEVICE_CONFIG_FAILURE,
} from 'actions/connectors/deviceconfigs';

import {
  CREATE_CENTER_ACL_FAILURE,
  UPDATE_CENTER_ACL_FAILURE,
  DELETE_CENTER_ACL_FAILURE,
  CREATE_GLOBAL_CENTER_ACL_FAILURE,
  UPDATE_GLOBAL_CENTER_ACL_FAILURE,
  DELETE_GLOBAL_CENTER_ACL_FAILURE,
} from 'actions/acls';

import { UPDATE_SHARED_TENANTS_FAILURE } from 'actions/tenants';

import { UPDATE_CONFIGURATION_FAILURE } from 'actions/configurations';

import getType from 'utils/getType';

export const initialState = [];

export const resetErrors = () => initialState;

export const preserveFailedStatusErrors = (type) => (errors) => {
  if (errors.length === 0) return initialState;

  return errors.filter(
    ({ failedAction }) =>
      failedAction && getType(failedAction) !== getType(type)
  );
};

export const setErrors =
  ({ handlerType = 'unhandled' }) =>
  (state, action) => {
    const { payload, error: showError } = action;

    if (!showError) return [];

    const rawErrors = Array.isArray(payload) ? payload : [payload];

    const errors = rawErrors.map((rawError) => ({
      ...rawError,
      handlerType,
    }));

    return errors;
  };

const ignoreErrorHandler = (state) => state;
const formErrorHandler = setErrors({ handlerType: 'form' });
const forbiddenErrorHandler = setErrors({ handlerType: 'forbidden' });
const notFoundErrorHandler = setErrors({ handlerType: 'not_found' });

const handledErrorTypes = {
  [SET_CLIENT_FORM_ERRORS]: formErrorHandler,

  [FORBIDDEN]: forbiddenErrorHandler,
  [NOT_FOUND]: notFoundErrorHandler,

  [ADD_USER_FAILURE]: formErrorHandler,
  [UPDATE_USER_FAILURE]: formErrorHandler,

  [UPDATE_CURRENT_USER_FAILURE]: formErrorHandler,
  [UPDATE_CURRENT_USER_PASSWORD_FAILURE]: formErrorHandler,

  [UPDATE_USER_PASSWORD_FAILURE]: formErrorHandler,
  [UPDATE_USER_ROLE_FAILURE]: formErrorHandler,

  [ADD_CENTER_FAILURE]: formErrorHandler,
  [UPDATE_CENTER_FAILURE]: formErrorHandler,
  [DELETE_CENTER_FAILURE]: formErrorHandler,

  [CREATE_ROLE_FAILURE]: formErrorHandler,
  [UPDATE_ROLE_FAILURE]: formErrorHandler,

  [UPDATE_RIGHTS_FOR_ROLE_FAILURE]: formErrorHandler,

  [GET_DEVICE_FAILURE]: ignoreErrorHandler,
  [ADD_DEVICE_FAILURE]: formErrorHandler,
  [UPDATE_DEVICE_FAILURE]: formErrorHandler,

  [UPDATE_CONNECTOR_FAILURE]: formErrorHandler,

  [ADD_CONNECTOR_DEVICE_CONFIG_FAILURE]: formErrorHandler,
  [UPDATE_CONNECTOR_DEVICE_CONFIG_FAILURE]: formErrorHandler,

  [CREATE_CENTER_ACL_FAILURE]: formErrorHandler,
  [UPDATE_CENTER_ACL_FAILURE]: formErrorHandler,
  [DELETE_CENTER_ACL_FAILURE]: formErrorHandler,

  [CREATE_GLOBAL_CENTER_ACL_FAILURE]: formErrorHandler,
  [UPDATE_GLOBAL_CENTER_ACL_FAILURE]: formErrorHandler,
  [DELETE_GLOBAL_CENTER_ACL_FAILURE]: formErrorHandler,

  [UPDATE_SHARED_TENANTS_FAILURE]: formErrorHandler,

  [REQUEST_PASSWORD_RESET_FAILURE]: formErrorHandler,
  [RESET_PASSWORD_FAILURE]: formErrorHandler,
  [REQUEST_ACCOUNT_UNLOCK_FAILURE]: formErrorHandler,
  [UNLOCK_ACCOUNT_FAILURE]: formErrorHandler,

  [UPDATE_CONFIGURATION_FAILURE]: formErrorHandler,
};

const detector = (type) => {
  // Handled errors
  if (handledErrorTypes[type]) return handledErrorTypes[type];

  // Reset errors
  if (type === LOCATION_CHANGE) return resetErrors;
  if (type === RESET_ERROR) return resetErrors;
  if (type && type.endsWith('SUCCESS')) return preserveFailedStatusErrors(type);

  // Save unhandled errors
  if (type && (type.endsWith('FAILURE') || type.endsWith('ERROR'))) {
    return setErrors({});
  }

  return (state) => state;
};

export default createReducer(detector, initialState);
