// @flow

import enMessages from './en.json';
import nlMessages from './nl.json';
import frMessages from './fr.json';
import deMessages from './de.json';

const languages = {
  nl: { messages: nlMessages, label: 'Nederlands' },
  en: { messages: enMessages, label: 'English' },
  fr: { messages: frMessages, label: 'Français' },
  de: { messages: deMessages, label: 'German' },
};

export default languages;
