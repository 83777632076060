import createReducer from 'reducers/createReducer';
import getType from 'utils/getType';

import { RESET_SUCCESS } from 'actions/success';
import { LOCATION_CHANGE } from 'actions/router';

export const initialState = {};

export const setSuccess = (state, { type, payload }) => ({
  type: getType(type),
  payload,
});

export const resetSuccess = () => initialState;

const setResourceSuccess =
  (resource, newId) =>
  (state, { type, payload: { request, response } }) => {
    if (!request && !response) return state;

    let id;

    // eslint-disable-next-line prefer-destructuring
    if (!id && request) id = request.id;
    if (!id && response) id = response[newId];

    return { type: getType(type), [resource]: { id } };
  };

const setUserSuccess = setResourceSuccess('user', 'userId');
const setCenterSuccess = setResourceSuccess('center', 'centerId');

const detector = (type) => {
  if (type.includes('USER') && type.endsWith('SUCCESS')) return setUserSuccess;

  if (type.includes('CENTER') && type.endsWith('SUCCESS'))
    return setCenterSuccess;

  if (type === LOCATION_CHANGE) return resetSuccess;
  if (type === RESET_SUCCESS) return resetSuccess;
  if (type.endsWith('FAILURE')) return resetSuccess;

  // Save entire payload for success without handler
  if (type.endsWith('SUCCESS')) return setSuccess;

  return (state) => state;
};

export default createReducer(detector, initialState);
