// @flow

import * as React from 'react';
import { connect } from 'react-redux';
import md5 from 'blueimp-md5';

import classNames from 'classnames';
import { resetSuccess as resetSuccessAction } from 'actions/success';
import { resetError as resetErrorAction } from 'actions/error';

type Props = {
  messages?: Array<string>, // Optional
  message?: string,
  success?: boolean,
  warning?: boolean,
  danger?: boolean,
  primary?: boolean,
  icon?: string,
  style?: Object,
  close?: boolean,

  onClose?: () => void,
  resetError: () => void,
  resetSuccess: () => void,
};

class Flash extends React.Component<Props> {
  static defaultProps = {
    close: true,
  };

  close() {
    const { resetError, resetSuccess } = this.props;

    resetError();
    resetSuccess();
  }

  render() {
    const {
      icon,
      primary,
      success,
      warning,
      danger,
      message,
      messages,
      style,
      close,
      onClose,
    } = this.props;

    const getIcon = () => {
      if (icon) return icon;

      if (primary) return 'info';
      if (success) return 'check_circle';
      if (warning) return 'warning';
      if (danger) return 'error';

      // default
      return 'error';
    };

    const getClassNames = () => {
      const defaultLevel = !(success || warning || danger || primary);

      return classNames('alert', {
        'alert-success': success,
        'alert-warning': warning,
        'alert-danger': danger || defaultLevel,
        'alert-primary': primary,
      });
    };

    if (!message && (!messages || !messages.length)) return null;
    return (
      <div className={getClassNames()} style={style}>
        <i className="material-icons">{getIcon()}</i>
        <ul>
          {message ? <li>{message}</li> : null}
          {messages && messages.length
            ? messages.map((msg) => <li key={md5(msg)}>{msg}</li>)
            : null}
        </ul>
        {close ? (
          <button
            type="button"
            className="alert-close-btn"
            onClick={() => (onClose ? onClose() : this.close())}
          >
            <i className="material-icons">clear</i>
          </button>
        ) : null}
      </div>
    );
  }
}

const mapDispatchToProps = {
  resetError: resetErrorAction,
  resetSuccess: resetSuccessAction,
};

export default connect(null, mapDispatchToProps)(Flash);
